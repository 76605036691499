import produce from "immer";
import { Bar, mixins } from "vue-chartjs";
import { minifyChartAmount } from "../../utils/utilities";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["options", "stacked", "minimum"],
  mounted() {
    this.renderChart(
      this.chartData,
      produce(this.options || {}, draft => {
        draft.scaleBeginAtZero = false;
        draft.scales = {
          yAxes: [
            {
              ticks: {
                min: parseInt((this.minimum || 0) * 0.95),
                callback: minifyChartAmount
              },
              stacked: this.stacked || false
            },
          ]
        };
      })
    );
  }
};
