<template lang="pug">
transition(name="modal")
  div
    .modal-mask(style="z-index: 100000")
      div(style="margin-top:10%")
        .modal-dialog(style="width: 500px;max-width: 100%;height:200px !important" role="document")
          .modal-content.rounded-2
            .modal-body.p-0
             //-   loader(:loading="saving")
             div
                .d-flex.justify-content-between.py-4.px-5.mt-1
                  h3.m-0.text-dark.font-weight-bold Your registration is complete!
                  a.bg-white.border-none(
                    href="javascript:;"
                    role="button",
                    data-dismiss="modal",
                    aria-label="Close"
                    @click="toggleBackToEtalaseModal()"
                  )
                    div
                      img(:height="14" :width="14" src="@/assets/img/close.png")           
                .d-flex.justify-content-between.px-5
                  span Hello, {{$store.state.auth.username}}! Welcome to Matador. You can continue your order after this.
                .d-flex.justify-content-center.px-5                
                  img(src="@/assets/img/done.png" style="margin:auto" :height="200" :width="200")
                .d-flex.justify-content-center.py-4.px-5.mt-1
                  b-button.btn-confirm.resend(@click="backToEtalase")
                    h5 Go To Catalogue     
                  
</template>
<script>
export default {
  methods:{
      toggleBackToEtalaseModal(){
      this.$store.commit("setShowBackToEtalaseModal", !this.$store.state.showBackToEtalaseModal);
    },
    backToEtalase(){
      this.$store.commit("setShowBackToEtalaseModal", false);
      this.$router.push({path: '/catalogue'});    }
  }
}

</script>
<style lang="scss" scoped>
.btn-confirm{
    width: 500px !important;
     max-width: 90% !important;
    border-radius: 10px !important;
    &.resend{
        background:  white !important;
        border:  2px solid #1877F2!important;
        color: #1877F2;
    }
}
</style>