<template lang="pug">
transition(name="modal")
  .modal-mask
    .modal-wrapper
      .modal-dialog.modal-dialog-scrollable.modal-md(role="document")
        .modal-content
          .modal-body.ml-3.mr-3
            div.mt-4
              .matador-h3.card-title.matador-text-01 Offline Payment
            div.mt-3
              .matador-text-01.mb-1 Date
              datepicker(style="width:100%" v-model="date" placeholder="Select Date"  input-class="form-control" calendar-class="scrolling")
            div.mt-3
              .matador-text-01.mb-1 Name
              input.form-control(v-model="name" type="text" placeholder="Your Name")
            div.mt-3
              .matador-text-01.mb-1 Total Amount
              input.form-control(v-model="total_amount" type="number" placeholder="Total amount")
            div.mt-3
              .matador-text-01.mb-1 Receipt
              button.btn.btn-sm.btn-outline-success.d-flex.align-items-center.justify-content-center.mb-4( style="background-color:#FAFAFC;width:40%;border:1px dashed #6DDF92" @click="selectPhoto = true" v-if="!!image") 
                div.mr-2.h5.m-0
                  check
                  | A photo has been selected
              button.btn.btn-sm.d-flex.align-items-center.justify-content-center.mb-4(style="background-color:#FAFAFC;width:40%;border:1px dashed #A1A4B1" @click="selectPhoto = true" v-if="!image" ) 
                div.mr-2
                  span.matador-text-02.mdi.mdi-18px.mdi-plus
                div.mr-2.m-0
                  .matador-text-02 Upload Receipt 
            div
              .py-3.d-flex.justify-content-end.align-items-center
                button.border-0.bg-white.py-1.px-4.text-danger(style="text-transform: none;" @click="$emit('close')") Cancel
                button.btn-sm.btn.btn-primary.py-1(style="width:200px;font-size:14px !important;text-transform: none;border-radius:5px" :disabled="!allowPost" @click="submitOfflinePayment()") Mark as paid
          
    upload-modal( 
        v-if="selectPhoto" 
        title="Select an Image" 
        :placeholder="require('@/assets/img/car-placeholder-square.png')"
        :ratio="1"
        @selected="onSelectPhoto" 
        @close="selectPhoto = false" 
    )
              
          
</template>
<script>
import Check from "vue-material-design-icons/Check.vue";
import UploadModal from "@/components/Matador/UploadModal.vue";
import VueTimepicker from 'vue2-timepicker'
import Datepicker from "vuejs-datepicker";
import dayjs from 'dayjs';
import {postOfflinePayment} from '@/utils/api';
export default {
  data() {
    return {
      selectPhoto:false,
      image:null,
      date:null,
      name:"",
      total_amount:null,
      payment_method:'offline'
    }
  },
  props:['dataOrder'],
  components:{
    Datepicker,
    VueTimepicker,
    UploadModal,
    Check,
  },
  computed:{
    allowPost(){
      return this.image != null && this.date !=null && this.total_amount !=null
    }
  },
  methods: {
    onCancel(){
      this.$emit('close');
    },
    
    onSelectPhoto(base64){
      this.image = base64;
      // console.log(this.image)
      this.selectPhoto = false;
      this.$emit('success')
    },

    async submitOfflinePayment(){
      try {
        let obj={
          order_id:this.dataOrder.order_id,
          payer_name:this.dataOrder.rentalSetting.account_name,
          total_amount:this.total_amount,
          payment_method:this.payment_method,
          invoice_id:this.dataOrder.invoice_id,
          payment_date:dayjs(this.date).format('DD/MM/YYYY HH:mm:ss'),
          receipt:this.image
        }
        // console.log(obj)
        let response = await postOfflinePayment(obj)
        if(response.data.code==200){
          this.$emit('offline')

        }
      } catch (error) {
        
      }
    }
  },
}
</script>