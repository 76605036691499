<template lang="pug">
component(
  :is="tag"
  @click.native="hideSidebar"
  class="nav-item"
  v-bind="$attrs"
  tag="li"
)
  a(v-if="name === 'Rental'")
    slot <rental-menu />

  a(v-else-if="name === 'Satellite'")
    slot <satellite-sub-menu />

  a.nav-link(v-else)
    slot
      i(v-if="icon" :class="icon")
      p {{ name }}
      span(v-if="counter" class="sidebar-counter text-white") {{ counter }}
</template>

<script>
import RentalMenu from "./SidebarDropdown.vue";
import SatelliteSubMenu from "@/pages/Matador/Vehicle/components/SatelliteSubMenu.vue";

export default {
  name: "sidebar-link",
  inheritAttrs: false,
  components: {
    RentalMenu,
    SatelliteSubMenu,
  },
  inject: {
    autoClose: {
      default: screen.width < 760,
    },
    addLink: {
      default: () => {},
    },
    removeLink: {
      default: () => {},
    },
  },
  props: {
    name: String,
    icon: String,
    counter: Number,
    tag: {
      type: String,
      default: "router-link",
    },
  },
  methods: {
    hideSidebar() {
      if (this.autoClose && screen.width < 760) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      return this.$el.classList.contains("active");
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
};
</script>
<style></style>
