<template lang="pug">
transition(name="modal")
  div
    .modal-mask(style="z-index: 100000")
      .modal-wrapper
        .modal-dialog(style="width: 500px;max-width: 100%;margin-top: 15px;" role="document")
          .modal-content.rounded-2
            .modal-body.p-0
             //-   loader(:loading="saving")
             div
                .d-flex.justify-content-between.py-4.px-5.mt-1
                  .matador-h3.m-0.matador-text-01 Verify your account
                  a.bg-white.border-none(
                    href="javascript:;"
                    role="button",
                    data-dismiss="modal",
                    aria-label="Close"
                    @click="toggleVerificationModal()"
                  )
                    div
                      img(:height="14" :width="14" src="@/assets/img/close.png")           
                .d-flex.justify-content-between.px-5.mb-4
                  .matador-text-03 Account activation link has been sent to the e-mail address you provided. Please click on that link to verify your email address.
                .d-flex.justify-content-between.px-5(style="margin-top:50px;margin-bottom:60px")               
                  img(src="/mail.png" style="margin:auto")
                .d-flex.justify-content-center.py-4.px-5.mt-1
                  b-button.btn-confirm.resend(@click="resendEmail")
                    h4 Resend Email     
                  
</template>
<script>
import { postResendEmail } from '@/utils/api';
export default {
  props: ['user_id'],
  methods:{
      toggleVerificationModal(){
      this.$store.commit("setShowVerificationModal", !this.$store.state.showVerificationModal);
    },
    resendEmail(){
      if(this.user_id){
        postResendEmail({user_id: this.user_id}).then(({ data: { code, messgae,status } }) => {
              if(code==200){
                this.$toast.open('Your Verification Email Successfully Sent');
              }else{
                this.$toast.error('Your Verification Email Failed to Sent');
              }
        });}
      else{
        postResendEmail({user_id: localStorage.getItem('user_id')}).then(({ data: { code, messgae,status } }) => {
              if(code==200){
                this.$toast.open('Your Verification Email has been Successfully Sent');
              }else{
                this.$toast.error('Your Verification Email Failed to Send');
              }          
        });        
      }
      console.log(this.$store.state.showVerificationModal)
      this.toggleVerificationModal()
      console.log(this.$store.state.showVerificationModal)
    }
  }
}

</script>
<style lang="scss" scoped>
.btn-confirm{
    width: 500px !important;
     max-width: 90% !important;
    border-radius: 10px !important;
    &.resend{
        background:  white !important;
        border:  2px solid #1877F2!important;
        color: #1877F2;
    }
}
</style>