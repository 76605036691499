<template lang="pug">
transition(name="modal")
  div
    .modal-mask
      .modal-wrapper
        .modal-dialog(style="width: 520px;max-width: 95%;margin-top: 15px;" role="document")
          .modal-content.rounded-2
            .modal-body.p-0
              loader(:loading="saving")
                .d-flex.justify-content-between.border-bottom.py-3.px-4.mt-1
                  h4.h5.m-0.text-dark.font-weight-bold {{etal? 'Edit product to be rented':'Add product to be rented'}}
                  a.bg-white.border-none(
                    href="javascript:;"
                    role="button",
                    data-dismiss="modal",
                    aria-label="Close"
                    @click="$emit('close')"
                  )
                    div
                      img(:height="14" :width="14" src="@/assets/img/close.png")
                .py-4.my-2.px-4
                  button.btn.btn-block.btn-lg.btn-outline-success.d-flex.align-items-center.justify-content-center.mb-4(@click="selectPhoto = true" v-if="!!image") 
                    div.mr-2.h4.m-0
                      check
                    | A photo has been selected
                  button.btn.btn-block.btn-lg.btn-outline-primary.d-flex.align-items-center.justify-content-center.mb-4(@click="selectPhoto = true" v-if="!image") 
                    div.mr-2.h4.m-0
                      camera
                    | {{etal? 'Edit Product Photo' : 'Add Product Photo' }} 
                  .form-group
                    label Vehicle Type
                    b-form-select(:options="optionTypes" v-model="vehicle_type")
                  .form-group
                    label Series
                    b-form-select(:options="optionSeries" v-model="series" :disabled="optionSeries.length == 0")
                  .form-group
                    label Description
                    textarea.form-control(type='text' placeholder='Insert description', v-model="description" rows="6")
                  div(style="display:flex;")
                    p(style="color: #adb5bd;fontSize:12px;margin-right:2px;") Rental Fee
                    div.borderEtalase              
                  .form-group
                    label Per Month
                    input.form-control(v-model="price_month" type="number" placeholder="Rupiah")
                  .form-group
                    label Per Day
                    input.form-control(v-model="price_day" type="number" placeholder="Rupiah")
                  .form-group
                    label Per Hour
                    input.form-control(v-model="price_hour" type="number" placeholder="Rupiah")
                  .form-group
                    label Per Km
                    input.form-control(v-model="price_km" type="number" placeholder="Rupiah")
                  div(style="display:flex;")
                    p(style="color: #adb5bd;fontSize:12px;margin-right:2px;") VIN List
                    div.borderEtalase  
                  div(v-show="parent.length >0")
                    div(v-for=" (item,index) in parent" :key="index")
                      .row
                        .col-11(style="padding-right:10px !important;")
                          .form-group
                            b-form-select(:options="optionVIN" v-model="item.unit_id" placeholder="VIN")
                            //- input.form-control(v-model="item.vin" placeholder="VIN")
                        .col-1.py-2(style="padding-left:2px !important;")
                          a(@click="deleteAnotherVin(index)")
                            i.icon-danger(class=" fa far fa-times-circle fa-2x" )
                  div(v-show="optionVIN.length > 0")          
                    button.px-4.btn-sm.btn.btn-outline-primary(style="text-transform: none;" icon="fa fa-plus" @click="addAnotherVin" :disabled="!series || statusAddVIN ") Add Another
                .py-3.px-4.d-flex.justify-content-end.align-items-center
                  button.border-0.bg-white.py-1.px-5.text-danger(style="text-transform: none;" @click="onCancel()") Cancel
                  button.px-4.btn-sm.btn.btn-primary(style="text-transform: none;"  @click="onSave()" :disabled="!allowToPost") {{ !etal ? 'Add' : 'Update' }}
                
    upload-modal( 
      v-if="selectPhoto" 
      title="Select an Image" 
      :placeholder="require('@/assets/img/car-placeholder-square.png')"
      :ratio="1.65"
      @selected="onSelectPhoto" 
      @close="selectPhoto = false" 
    )
    //- vehicle-type-modal(v-if="showTypeModal" @close="showTypeModal = false" @success="getTypes")
    //- vehicle-series-modal(v-if="showSeriesModal" @close="showSeriesModal = false" @success="getSeries" )
    confirmation-modal(
      
        v-if="showCancelModal"
        @close="showCancelModal = false" 
        @confirm="$emit('close');" 
        title="Discard Changes"
        confirm-text="Yes"
      
      )
        p.m-0
          | Are you sure want to discard changes you made ? 
</template>

<script>
import Camera from "vue-material-design-icons/Camera.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import Check from "vue-material-design-icons/Check.vue";
import UploadModal from "./UploadModal.vue";
import VehicleTypeModal from "./VehicleTypeModal.vue";
import VehicleSeriesModal from "./VehicleSeriesModal.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";

import { get } from "lodash";
import { getVehicleType, getSeriesByVehicleType, postEditVehicle, postAddVehicle, getVehicleDetail, getDeviceAll, getEtalaseDetail } from '../../utils/api'
import {  postAddEtalase, postEditEtalase, getVehicleVINBySeries } from '../../utils/api'
import { object } from 'yup/lib/locale';
export default {
  data(){
    return {
      unit_id: null,
      image: null,
      vehicle_name: '',
      vin: '',
      device_id: '',
      vehicle_type: null,
      series: null,
      wiper_width: null,
      optionDevices: [],
      optionTypes: [],
      optionSeries: [],
      optionVIN:[],
      selectPhoto: false,
      saving: false,
      showTypeModal: false,
      showSeriesModal: false,
      showCancelModal: false,
      description:null,
      price_month:null,
      price_day:null,
      price_hour:null,
      price_km:null,
      parent:[],
      description:null,
      vin:[],
      statusAddVIN:false,
      seriesEtal:true
    }
  },
  watch: {
    vehicle_type: {
      handler: function(newVal){
        if(!this.v) this.series = null;
          this.optionSeries = [];
          this.parent=[];
          this.optionVIN=[];
          this.getSeries(newVal);
        
      },
    },
    series: {
      handler: function(newVal){
        // console.log(this.etal,"isdc")
        if(!this.etal){
          if(!this.newVal) 
          { this.vin = null;
            this.parent=[];
            this.statusAddVIN=false;
            this.getVIN();
          }else{
            this.statusAddVIN=true;
          }
        }else{
          this.seriesEtal=false
          this.getVIN();
        }
      },
    },
    optionVIN:{
      handler:function(newVal){
        console.log(newVal);
        // console.log(this.optionVIN,"ini VIN")
      }
    },
    etal: {
      handler: function(newVal){
        if(newVal){
        // console.log(newVal.etalase_id,"ini newVal")
        var requestTime=(new Date()).getTime();
        var responseTime;
          this.saving=true;
            // this.vehicle_type = newVal.vehicle_type_id;
            // this.series = newVal.series
            // this.getSeries(this.vehicle_type,this.series);
            // this.description = newVal.description
            // this.price_month = newVal.price_per_month;
            // this.price_day = newVal.price_per_day;
            // this.price_hour = newVal.price_per_hour;
            // this.price_km = newVal.price_per_km;
          getEtalaseDetail({ etalase_id: newVal.etalase_id }).then(({ data: { result: vehicle } }) => {
            this.vehicle_type = vehicle.vehicle_type_id;
            this.series = vehicle.series
            this.getSeries(this.vehicle_type,this.series);
            this.description = vehicle.description
            this.getVIN(vehicle.products);
            this.price_month = vehicle.price_per_month;
            this.price_day = vehicle.price_per_day;
            this.price_hour = vehicle.price_per_hour;
            this.price_km = vehicle.price_per_km;
          })
          // console.log(responseTime,"ini wakturesponse time")
          // console.log(responseTime-requestTime,"ini wakturesponse time")
          setTimeout(() => {
            this.saving=false;
          }, responseTime-requestTime);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    Camera,
    Plus,
    Check,
    UploadModal,
    VehicleTypeModal,
    VehicleSeriesModal,
    ConfirmationModal
  },
  props: ['close', 'etal', 'success'],  
  computed: {
    postData(){
      const {
        price_month,
        price_day,
        price_hour,
        price_km,
        description,
        series,
        vehicle_type,
        parent,
        image,
      } = this;
      const postData = {
        price_month,
        price_day,
        price_hour,
        price_km,
        description,
        series,
        vehicle_type,
        parent,
        image
      }
      if(!!vehicle_type && this.optionTypes.length > 0) postData.type_name = this.optionTypes.filter(item => item.vehicle_type_id == vehicle_type)[0].name;
      return postData;
    },
    allowToPost(){
      const {
        price_month,
        price_day,
        price_hour,
        price_km,
        description,
        series,
        vehicle_type,
      } = this.postData;
      return !!price_month && !!price_day && !!price_hour && !!price_km && !!series && !!description && !!vehicle_type;
    },
    price_monthComp(){
      return this.mataUang(this.price_month);
    }
  },
  methods: {
    deleteAnotherVin(params){
      this.parent.splice(params,1);
      this.statusAddVIN=false;
    },
    addAnotherVin(){
    
      if(this.parent.length < this.optionVIN.length){
        
        this.parent.push({
          value:this.vin
        })
        for (let indexx = 0; indexx < this.optionVIN.length; indexx++) {
          for (let indexss = 0; indexss < this.parent.length; indexss++) {
              if (this.parent[indexss].unit_id==this.optionVIN[indexx].unit_id) {
                this.optionVIN[indexx].disabled=true;
              }
            
          }
        }
        this.statusAddVIN=false;
      }else{
        this.statusAddVIN=true;
      }
    },
    mataUang(req){
      var	number_string = req.toString();
      var sisa 	= number_string.length % 3
      var rupiah 	= number_string.substr(0, sisa)
      var ribuan 	= number_string.substr(sisa).match(/\d{3}/g)
          
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
     
      return rupiah;
    },
    async getVIN(payload){
      
      if(this.series){
        if(this.etal && this.seriesEtal){
          this.saving=true;
        }
        getVehicleVINBySeries({series:this.series,vehicle_type_id:this.vehicle_type}).then(({ data: { code,result } })=>{
          if (code == 200 && result.length != 0) {  
            this.optionVIN = result.vehicle.map(item => {
                    item.value = item.unit_id;
                    item.text = `${item.vin}`;
                  return item;
                  }) 
          }else{
            this.parent=[];
            this.optionVIN=[];
            this.saving=false;
            this.$toast.error("VIN is empty")
          }
              if(!!payload){
                for (let indexs = 0; indexs < payload.length; indexs++) {
                  let UId=
                  {
                    unit_id:payload[indexs].unit_id,
                  }
                  this.parent.push(UId);
                }
                /* this.vin = this.optionVIN.filter(item => item.vin === payload)[0].vin;  */
              } 
        
        setTimeout(() => {
          this.saving=false;
        }, 3000);
        })
      }
    },
    getSeries(payload,payloadSeries){
      getSeriesByVehicleType(payload).then(({ data: { result } }) => {
        this.optionSeries = result.map(item => {
          item.value = item.series;
          item.text = `${item.series}`;
          return item;
        });
        if(!!payloadSeries){
        this.series = this.optionSeries.filter(item => item.series === payloadSeries)[0].series;
        }
      }); 
    },
    getTypes(payload){
      getVehicleType().then(({ data: { result } }) => {
        this.optionTypes = result.map(item => {
          item.value = item.vehicle_type_id;
          item.text = item.name.split(' ').map(str => {
            return str[0].toUpperCase() + str.substring(1)
          }).join(' ')
          return item;
        });
        if(!!payload){
          this.vehicle_type = this.optionTypes.filter(item => item.name === payload.name)[0].vehicle_type_id;
        }
      });
    },
    onSelectPhoto(base64){
      this.image = base64;
      this.selectPhoto = false;
    },
    async onSave(){
      this.saving = true;
      try {
        let unit=[];
        for (let index = 0; index < this.parent.length; index++) {
          unit.push(this.parent[index].unit_id)
        }
        if(!this.image){
          this.image=null;
        }
        if(this.etal){
          //update data api etalase
          // console.log(this.vehicle_type);
          var obj={
            vehicle_type_id:this.vehicle_type,
            etalase_id:this.etal.etalase_id,
            series:this.series,
            description:this.description,
            image:this.image,
            price_per_month:this.price_month,
            price_per_day:this.price_day,
            price_per_hour:this.price_hour,
            price_per_km:this.price_km,
            unit_id:unit
          }
          var response= await postEditEtalase(obj) 
          // console.log(obj)
        }else{
          //add data api etalase
          var obj={
            vehicle_type_id:this.vehicle_type,
            series:this.series,
            description:this.description,
            image:this.image,
            price_per_month:this.price_month,
            price_per_day:this.price_day,
            price_per_hour:this.price_hour,
            price_per_km:this.price_km,
            unit_id:unit
          }
          // console.log(obj)
          var response= await postAddEtalase(obj) 
        }
        if(response.data.code == 200){
          this.saving = false;
          this.$emit('success');  
        }
      } catch (error) {
        console.log(error);
      }
      
    },
    OnAddSeries(value){
      localStorage.setItem('vehicle_type_id',value);
      this.vehicle_type = value;
      this.showSeriesModal = true
    },
    async onCancel(){
      this.showCancelModal = true;
    },
  },
  async mounted(){
    this.parent=[];
  
      this.getTypes();
      
    getDeviceAll({
      type: 'empty_unit'
    }).then(({ data: { result } }) => {
      this.optionDevices = result.map(item => {
        item.value = item.device_id;
        item.text = item.description;
        return item;
      });
    });
  
  }
}
</script>
<style>
  .borderEtalase{
    width:90%; 
    height:10px;
    border-bottom:0.1px solid #adb5bd;
  }
  @media(max-width:1024px){
    .borderEtalase{
      width:90%; 
      height:10px;
      border-bottom:0.1px solid #adb5bd;
    } 
  }
  @media(max-width:768px){
    .borderEtalase{
      width:90%; 
      height:10px;
      border-bottom:0.1px solid #adb5bd;
    } 
  }
  .fa-times-circle:before {
    content: "\f057";
}
</style>