<template>
<div>
      <div class="nav-dropdown menu-item" @click="openDropdown() ">
        <i class="mdi mdi-train-car"></i>
        <p>Rental</p>
        <svg viewBox="0 0 1030 638" width="10" :style="arrowStyle">
          <path d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z" fill="#8B90A0"></path>
        </svg>
      </div>
      <transition v-if="this.$store.state.auth.role=='superadmin'|| this.$store.state.auth.role=='admin' || this.$store.state.auth.role=='staff' " name="fade"  appear>
        <div class="sub-item" v-if="isOpen  ">
          <li  v-for="(item,index) in rentalMenuAdmin" :key="index" >
              <router-link ref="coba"  :to="item.path" class="subnav-dropdown ">
                <p>{{item.title}}</p>
              </router-link>  
          </li>
        </div>
      </transition>
      <transition v-else name="fade"  appear>
        <div class="sub-item" v-if="isOpen  ">
          <div  v-for="(item,index) in (this.$store.state.auth.role=='user')? rentalMenuListUser:rentalMenuListGuest" :key="index">
              <router-link :to="item.path" class="subnav-dropdown">
                <p>{{item.title}}</p>
              </router-link>  
          </div>
        </div>
      </transition>
</div>
    
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  name: "sidebar-dropdown",
  computed: {
    auth(){
      return this.$store.state.auth;
    },
    ...mapGetters({
      statusCart:'gettersCartStatus'
    }),
    rentMenuUser(){
      if(this.statusCart){
        return this.rentalMenuList
      }else{
        return this.rentalMenuListTwo
      }
    }
  },
  data() {
    return {
      isOpen:"false",
      arrow:"rotate(0deg)",
      rentalMenuAdmin:[
        {
          title:"Dashboard Rental",
          path:"/dashboardRental",
          name:"DashboardRental"
        },
        {
          title:"Catalogue",
          path:"/catalogue",
          name:"Catalogue"
        },
        {
          title:"Manage Catalogue",
          path:"/manageCatalogue",
          name:"Manage Catalogue"
        },
        {
          title:"Rent List",
          path:"/rentList",
          name:"Rental List"
        },
        {
          title:"Order List",
          path:"/orderList",
          name:"Order List"
        },
        
      ],
      rentalMenuListUser:[
        {
          title:"Catalogue",
          path:"/catalogue",
          name:"Catalogue"
        },
        {
          title:"Rent List",
          path:"/rentList",
          name:"Rental List"
        },
      ],
      rentalMenuListGuest:[
        {
          title:"Catalogue",
          path:"/catalogue",
          name:"Catalogue"
        },
      ],
    }
  },
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: screen.width < 760
    },
    addLink: {
      default: ()=>{}
    },
    removeLink: {
      default: ()=>{}
    }
  },
  props: {
    name: String,
    icon: String,
    counter: Number,
    tag: {
      type: String,
      default: "router-link"
    }
  },
  computed:{
    arrowStyle(){
      return {
        transform:this.arrow
      }
    }
  },
  methods: {
    hideSidebar() {
      if (this.autoClose && screen.width < 760) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      return this.$el.classList.contains("active");
    },
    openDropdown(){
      if(this.isOpen){
        this.isOpen= !this.isOpen;
        this.arrow="rotate(0deg)";
        // console.log(this.arrow)
      }else{
        this.isOpen= !this.isOpen;
        this.arrow="rotate(180deg)";
        // console.log(this.arrow)
      }
    }

  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
      this.isOpen=false
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  }
};
</script>
<style>

</style>
