<template lang="pug">
.wrapper(v-bind:class="this.$store.state.theme")
  #main-panel.main-panel(v-bind:class="this.$store.state.theme")
    top-navbar(id="top-navbar" v-bind:class="this.$store.state.theme")
    dashboard-content
        
  side-bar
    template(slot='links')
      sidebar-link(
        v-for="sidebar in sidebars"
        v-if="sidebar.allow"
        :key="sidebar.id"
        :to="sidebar.to"
        :name="sidebar.name",
        :icon="sidebar.icon"
      )

  upload-modal(
    v-if='showAvatarModal' 
    title='Change Profile Picture' 
    :placeholder="require('@/assets/img/avatar.png')" 
    :ratio='1' 
    @selected='onSelectAvatar' 
    @close='toggleAvatarModal'
  )

  //- feedback-modal(v-if="showFeedbackModal")
  logout-modal(v-if="showLogoutModal")
  register-modal(v-if="showRegisterModal"
   title='' 
    @close='toggleRegisterModal')
  verification-modal(v-if="showVerificationModal"
   title='Verify to Matador' 
    @close='toggleVerificationModal')
  back-to-etalase-modal(v-if="showBackToEtalaseModal"
   title='Back to Etalase' 
    @close='toggleVerificationModal')    
  interactive-alert-modal

  sos-floating-button
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import UploadModal from "../../components/Matador/UploadModal.vue";
/* import FeedbackModal from "../../components/Matador/FeedbackModal.vue"; */
import LogoutModal from "../../components/Matador/LogoutModal.vue";
import VerificationModal from "../../components/Matador/Register/VerificationModal.vue";
import BackToEtalaseModal from "../../components/Matador/Register/BackToEtalaseModal.vue";
import RegisterModal from "../../components/Matador/Register/RegisterModal.vue";
import InteractiveAlertModal from "../../components/Matador/InteractiveAlertModal.vue";
import SosFloatingButton from "../../components/Matador/SOSFloatingButton.vue";
import { getCurrentUser, postAvatar, getAlertCounter } from "@/utils/api";
import { getUserDetail, postEmailVerification } from "../../utils/api";
import { mapGetters } from "vuex";

import config from "@/config";

import { initSidebar } from "./library";
import EV from "@/utils/emitter";

export default {
  data() {
    return {
      currentTheme: localStorage.getItem("theme-color"),
      sidebars: [],
      bgUnivStat: null,
    };
  },
  components: {
    BackToEtalaseModal,
    RegisterModal,
    VerificationModal,
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UploadModal,
    SosFloatingButton,

    /*  FeedbackModal, */
    LogoutModal,
    InteractiveAlertModal,
  },
  computed: {
    ...mapGetters({
      auth: "gettersAuth",
    }),
    showAvatarModal() {
      return this.$store.state.showAvatarModal;
    },
    showBackToEtalaseModal() {
      return this.$store.state.showBackToEtalaseModal;
    },
    showFeedbackModal() {
      return this.$store.state.showFeedbackModal;
    },
    showLogoutModal() {
      return this.$store.state.showLogoutModal;
    },
    showRegisterModal() {
      return this.$store.state.showRegisterModal;
    },
    showVerificationModal() {
      return this.$store.state.showVerificationModal;
    },
    alertCounter() {
      return this.$store.state.alertCounter;
    },
  },
  methods: {
    toggleVerificationModal() {
      this.$store.commit(
        "setShowVerificationModal",
        !this.$store.state.showVerificationModal
      );
    },

    toggleBackToEtalaseModal() {
      this.$store.commit(
        "setShowBackToEtalaseModal",
        !this.$store.state.showBackToEtalaseModal
      );
    },

    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },

    syncUser(repeat = true) {
      if (this.$cookies.get("keepLogin") === "no") {
        this.$cookies.set("token", this.$cookies.get("token"), "1h");
      }
      getCurrentUser().then(({ data: { code, result: userData } }) => {
        if (code == 200) {
          this.$cookies.set("user_id", userData.user_id, "1y");
          if (userData.link) {
            userData.avatar = userData.link.replace(
              /http:\/\/\d+\.\d+\.\d+\.\d+/g,
              config.app.base_url
            );
          }
          this.$store.commit("setAuth", userData);
          getUserDetail(userData.user_id).then(({ data: { result, code } }) => {
            if (code === 200) {
              this.$store.commit("setSidebar", result.access);
            }
            this.$store.dispatch("getUserCart", this.auth.user_id);
          });
          // console.log(this.$store.state.auth.sidebar)
          repeat && setTimeout(this.syncUser, 120000);
        } else if (
          this.$route.query.user_id &&
          this.$route.query.verification_code
        ) {
          postEmailVerification({
            user_id: this.$route.query.user_id,
            email_verification_code: this.$route.query.verification_code,
          }).then(({ data: { code, result: userData, message } }) => {
            if (code == 200) {
              this.$store.dispatch("haveToLogin", false);
              this.$store.dispatch("setAfterLoginPathAction", "/registration");

              this.$store.commit("setAuth", userData);
              this.$store.commit("setSidebar", userData.access);
              this.$cookies.set(
                "keepLogin",
                this.keepLogin ? "yes" : "no",
                "1y"
              );
              this.$cookies.set(
                "token",
                userData.token,
                this.keepLogin ? "1y" : "1h"
              );
              this.$cookies.set(
                "user_id",
                userData.user_id,
                this.keepLogin ? "1y" : "1h"
              );
              this.$cookies.set(
                "role",
                userData.role,
                this.keepLogin ? "1y" : "1h"
              );
              this.$router.replace(this.afterLoginPath);
            }
          });
        } else {
          this.$store.commit("setAfterLoginPath", this.$route.path);
          // console.log(this.$route.name,"here")
          // console.log(this.$route.path,"heres")

          this.$router.replace("/login");
        }
      });
    },

    toggleAvatarModal() {
      this.$store.commit(
        "setShowAvatarModal",
        !this.$store.state.showAvatarModal
      );
    },

    toggleRegisterModal() {
      this.$store.commit(
        "setShowRegisterModal",
        !this.$store.state.showRegisterModal
      );
    },

    onSelectAvatar(base64) {
      // console.log(this.$store.state.auth.user_id)
      postAvatar({
        profile_picture: base64,
        user_id: this.$store.state.auth.user_id,
      }).then((response) => {
        // console.log(this.$store.state.auth.user_id)
        this.syncUser(false);
        this.$toast.open("Successfully changed profile photo.");
      });
    },

    detectModal() {
      const modalAppear =
        document.getElementsByClassName("modal-mask").length > 0;
      if (modalAppear) {
        document.getElementsByClassName("main-panel")[0].scrollTo(0, 0);
      }
      document.getElementsByClassName("main-panel")[0].style = modalAppear
        ? "overflow: hidden"
        : "overflow: scroll";
      setTimeout(this.detectModal, 500);
    },

    listenEmit() {
      const self = this;
      EV.on("global_message", (payload) => {
        payload = payload || {};

        switch (payload.event) {
          case "new_emergency":
            this.$store.commit("incTotalSOS");
            return self.$toast.error(payload.data.message, 3000);
        }
      });
    },

    listenSocket() {
      this.$store.dispatch("initCallback", {
        interval: 1000,
        timeout: 10,
        callback: (done) => {
          this.$store.commit(`listen_socket`);
          if (this.$store.state.socket) done();
        },
      });
    },

    initSidebar() {
      const self = this;
      this.$sidebar.displaySidebar(this.$cookies.get("show_sidebar") === "yes");
      this.$store.getters.initCallback(1000, 10, (done) => {
        if (!self.$store.state.auth || !self.$store.state.auth.role)
          throw new Error();

        self.sidebars = initSidebar(self);
        done();
      });
    },

    getStatistic() {
      let delay = config.app.socket_delay.update_dashboard || 200000;
      this.bgUnivStat = setInterval(() => {
        if (!this.$store.state.socket) return;
        const payload = {
          event: "universal",
          token: this.$cookies.get("token"),
        };

        this.$store.state.socket.emit("request", payload);
      }, delay);
    },
  },

  async mounted() {
    this.$store.dispatch("initSocket", { token: this.$cookies.get("token") });

    this.listenEmit();
    this.listenSocket();

    this.syncUser();
    this.initSidebar();
    this.getStatistic();
  },

  beforeDestroy() {
    if (this.bgUnivStat) clearInterval(this.bgUnivStat);
  },
};
</script>
