import Vue from "vue";
import DeleteOutline from "vue-material-design-icons/DeleteOutline.vue";

Vue.component("delete-icon", {
  components: {
    DeleteOutline
  },
  props: ["click"],
  data() {
    return {};
  },
  template: `<a class='text-secondary' href="javascript:;" role='button' @click="$emit('click')">
    <delete-outline class='icon-2x btn-delete'/>
  </a>`
});
