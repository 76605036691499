<template lang="pug">
div
  loader(:loading="loading")
    div
      div(v-if="isPdf")
        h3.mb-4.font-weight-bold Sensor Table Log
        div(v-html="pdfTable") {{ pdfTable }}
      .py-3.px-4.d-flex.justify-content-center.align-items-center(v-show="tableData.length == 0") There are no records to show 
      div(v-if="!isPdf")
        div.border-left.border-right.border-bottom.table-report.table-responsive
          b-table.m-0(
            ref="table"
            :busy.sync='isBusy' 
            :filter='search' 
            :items='tableData' 
            :fields='fields' 
            :perPage='perPage'
            :show-empty="true" 
            :currentPage="currentPage"
            :totalRows="totalRows"
            :filter-debounce="250"
            @filtered="onFiltered"
          )
            template(#cell(timestamp)="data")
              span {{ data.item.timestamp | datetime }}
        .mt-3
          b-pagination(
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          )
</template>

<script>
import { getReportData } from '../../../utils/api'
import dayjs from 'dayjs'
export default {
  components: {
    
  },
  data(){
    return {
      loading: false,
      tableData: [],
      pdfTable: '<h1>Hello</h1>',
      isBusy: false,
      search: '',
      fields: [
        { key: 'num', label: 'No.', sortable: !this.isPdf, class: '' },
        { key: 'timestamp', label: 'Date', sortable: !this.isPdf, class: '' },
        { key: 'temperature', label: 'Temperature', sortable: !this.isPdf, class: '' },
        { key: 'speed', label: 'Speed', sortable: !this.isPdf, class: '' },
        { key: 'signal', label: 'Signal', sortable: !this.isPdf, class: '' },
        { key: 'start_count', label: 'Start Count', sortable: !this.isPdf, class: '' },
        { key: 'total_mileage', label: 'Total Mileage', sortable: !this.isPdf, class: '' },
        { key: 'operate_mileage', label: 'Operate Mileage', sortable: !this.isPdf, class: '' },
        { key: 'total_time', label: 'Total Time', sortable: !this.isPdf, class: '' },
        { key: 'operate_time', label: 'Operate Time', sortable: !this.isPdf, class: '' },
        { key: 'idle_time', label: 'Idle Time', sortable: !this.isPdf, class: '' },
        { key: 'voltage', label: 'Voltage', sortable: !this.isPdf, class: '' },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 0
    }
  },
  watch: {
    reportToken: {
      immediate: true,
      handler: function(newVal){
        this.getReport()
      },
      deep: true
    }
  },
  props: ['device_id', 'dates', 'report-token', 'load', 'is-pdf'],
  methods: {
    onFiltered(){
      
    },
    getReport(){
      const {
        device_id,
        dates: [
          start_date,
          end_date
        ]
      } = this;
      if(device_id && start_date){
        this.loading = true;
        getReportData({
          report_data: 'sensor',
          device_id,
          start_date,
          end_date
        }).then(({ data: { code ,result } }) => {
          if(code == 200 && result.length != 0){
            this.loading = false;
              this.tableData = result.map((item, index) => {
                item.num = index+1;
              return item;
            });
            this.totalRows = result.length;
            this.$emit('load');
            if(this.isPdf) {
              this.perPage = result.length;
              this.populateNativeTable();
            }
          }else{
            this.loading = false;
            this.tableData=[];
          }
        })
      }
    },
    populateNativeTable(){
      let table = `<table class="table table-bordered m-0">`;
      table += `<thead>`;
      table += `<tr>`;
      this.fields.forEach(item => {
        table += `<th>${item.label}</th>`;
      });
      table += `</tr>`;
      table += `</thead>`;
      table += `<tbody>`;
      this.tableData.forEach(row => {
        table += `<tr>`;
        this.fields.forEach(item => {
          table += item.key == 'timestamp' ? `<td>
            ${dayjs(row[item.key]).format('YYYY-MM-DD HH:mm:ss')}
          </td>` : `<td>${row[item.key]}</td>`;
        });
        table += `</tr>`;
      });
      table += `</tbody>`;
      table += `</table>`;
      this.pdfTable = table;
    }
  }
}
</script>