<template lang="pug">
transition(name="modal")
  div
    .modal-mask(style="z-index: 100000")
      .modal-wrapper
        .modal-dialog(style="width: 500px;max-width: 100%;margin-top: 15px;" role="document")
          .modal-content.rounded-2
            .modal-body.p-0
             //-   loader(:loading="saving")
             div
                .d-flex.justify-content-between.py-4.px-5.mt-1
                  h3.m-0.text-dark.font-weight-bold {{loginOrSignUp? 'Sign Up To Matador': 'Login To Matador'}}
                  a.bg-white.border-none(
                    href="javascript:;"
                    role="button",
                    data-dismiss="modal",
                    aria-label="Close"
                    @click="closeRegisterModal()"
                  )
                    div
                      img(:height="14" :width="14" src="@/assets/img/close.png")
             div(v-if="loginOrSignUp")
                .d-flex.justify-content-between.px-5
                  p Please create an account to continue your order

                div(id="app")
                  .d-flex.justify-content-between.px-5
                    //- v-facebook-login.btn-social(app-id="637846533902485")
                    
                   
                .d-flex.justify-content-between.border-bottom.py-3.px-5.mt-1(style="padding-bottom:30px !important")
                    b-button.btn-social.ggl(@click="registerSocialMedia('google')")  
                     .d-flex
                      .col-1
                        img(:height="25" :width="25" src="@/assets/img/logo_google.png")
                      .col-11
                        h4 Sign Up With Google

                .form-group.px-5(v-if="failed")
                  .wrong-login.off.px-2.py-1( style="position:")
                    p.text-off() Registration Failed
                    p.text-off.error() {{textFailed}} 
                .form-group.py-1.px-5.mt-1
                    label UserName
                    input.form-control(v-model="username" required placeholder="Your Username" @click="turnOffFailedAuth")     
                .form-group.py-1.px-5.mt-1
                    label Email Address
                    input.form-control(v-model="email" required placeholder="Your Email Address"  @click="turnOffFailedAuth")   
                .form-group.py-1.px-5.mt-1
                    label Password
                    input.form-control(:type="passwordType" required v-model="password" placeholder="Your Password" @click="turnOffFailedAuth")
                    //- a.h3(@click="togglePassword" href="javascript:;" style="position: absolute;bottom: 1px;margin: 0;right: 10px;")
                    //-   .text-muted(v-if="passwordType === 'password'")
                    //-     eye-off asdasdasdas
                    //-   .text-primary(v-if="passwordType === 'text'")
                    //-     eye-on asdasdas                                         
                .form-group.py-1.px-5.mt-1
                 h5
                    a By signing up to this website, you’re agreeing to our 
                    a.text-blue-bold(href="#") Terms 
                    a and 
                    a.text-blue-bold(href="#") Privacy.     

                .d-flex.justify-content-between.border-bottom.py-1.px-5(style="padding-bottom:30px !important")
                    b-button.btn-social.confirm(@click="registerByEmail" :disabled="username==''||email==''||password==''")
                     h5 Sign Up       
                .form-group.py-1.px-5.mt-1
                 h5
                    a Already have an account? 
                    a.text-blue-bold(href="#" @click="toggleLoginAndSignUp(); turnOffFailedAuth();" ) Login Now

             div(v-if="!loginOrSignUp")
                .d-flex.justify-content-between.px-5
                  p Please login to your account to continue your order

                //- .d-flex.justify-content-between.py-2.px-5.mt-1
                //-     b-button.btn-social.fb(@click="") 
                //-      h4 Login with Facebook
                .d-flex.justify-content-left.border-bottom.py-3.px-5.mt-1(style="padding-bottom:30px !important")
                    b-button.btn-social.ggl(@click="registerSocialMedia('google')")
                     .d-flex
                      .col-1
                        img(:height="25" :width="25" src="@/assets/img/logo_google.png")
                      .col-11                    
                        h4 Login with Google  
                form(@submit="userLogin")  
                  .form-group.px-5(v-if="failed")
                    .wrong-login.off.px-2.py-1( style="position:")
                      p.text-off() Login Failed
                      p.text-off.error(style=" text-transform: capitalize;") {{errorMessage}}                      
                  .form-group.py-1.px-5.mt-1
                      label Email Address
                      input.form-control(type='email' @input="missMatch=false" v-model="email" placeholder="Your Email Address"  @click="turnOffFailedAuth")   
                  .form-group.py-1.px-5.mt-1
                      label Password
                      input.form-control(:type="passwordType" @input="missMatch=false" v-model="password" placeholder="Your Password"  @click="turnOffFailedAuth")
                      //- a.h3(@click="togglePassword" href="javascript:;" style="position: absolute;bottom: 1px;margin: 0;right: 10px;")
                      //- .text-muted(v-if="passwordType === 'password'")
                      //-   eye-off asdasdasdas
                      //- .text-primary(v-if="passwordType === 'text'")
                      //-   eye-on
                  .form-group.py-1.px-5.mt-1
                      checkbox(:checked="keepLogin" @switch="keepLogin = !keepLogin")
                        p.m-0 Keep me logged in                     
                  .d-flex.justify-content-between.border-bottom.py-1.px-5(style="padding-bottom:30px !important")
                      //- b-button.btn-social.confirm
                      matador-button(:full="true" :disabled="email==''||password==''" size="lg" nativeType="submit")
 
                        h5 Login      
                .form-group.py-1.px-5.mt-1
                 h5
                    a Don't have an account? 
                    a.text-blue-bold(href="#" @click="toggleLoginAndSignUp(); turnOffFailedAuth();" ) Sign Up                    

                                                                     
                                
                
            
</template>

<script src="https://accounts.google.com/gsi/client" async defer></script>

<script>
  import VFacebookLogin from 'vue-facebook-login-component'
import { postLogin, postSocialMedia, postRegister, postResendEmail, postEmailVerification } from '@/utils/api';
import EyeOff from "vue-material-design-icons/EyeOff.vue";
import EyeOn from "vue-material-design-icons/Eye.vue";
import Cropper from 'cropperjs'
export default {
  data(){
    return {
      failed:false,
      files: [],
      edit: false,
      cropper: true,
      password:'',
      passwordType:'Password',
      email:'',
      keepLogin:null,
      username:'',
      textFailed:''

    }
  },
  
  components: {
    VFacebookLogin,
    EyeOff,
    EyeOn,    
  },
  
  watch: {
   
  },
  props: ['close', 'title'],
  methods: {
    closeRegisterModal(){
      this.$store.commit("setShowRegisterModal", false);
    },    
    async registerSocialMedia(socialMedia){
      if(socialMedia=='google'){
        const googleUser = await this.$gAuth.signIn();
        let user_id =   googleUser.getBasicProfile().getId()
        let user_name =  googleUser.getBasicProfile().getName()
        let user_email=  googleUser.getBasicProfile().getEmail()
        console.log(user_id,user_name,user_email)
        try{
          postSocialMedia({social_id:user_id, email:user_email, name:user_name,register_via:socialMedia}).then(({ data: { code, result: userData, message,status } }) => {
            if(code == 200) {
              this.toggleLoginAndSignUp()  
              this.closeRegisterModal()
              this.$toast.open('You Have Successfully Registered');
              this.$store.commit("setLoginOrSignUp", !this.$store.state.loginOrSignUp);
              localStorage.setItem("user_id", userData.user_id);
              this.$store.commit('setAuth', userData);
              this.$store.dispatch('haveToLogin',false);
              this.$cookies.set("keepLogin", this.keepLogin ? "yes" : "no", "1y");
              this.$cookies.set("token", userData.token, this.keepLogin ? "1y" : "1h");
              this.$cookies.set("user_id", userData.user_id, this.keepLogin ? "1y" : "1h");
              this.$cookies.set("role", 'user', this.keepLogin ? "1y" : "1h");    
              if(userData.access){      
                this.$store.commit("setSidebar",userData.access);}
              if(!userData.organization_id){
              this.$router.push({path: '/registration'});}
            }else{
              this.$toast.error(message)
            }
          })
        }catch(error){
          console.error();
        }

      }
    },
    async checkLogin(socialMedia){
      const googleUser = await this.$gAuth.signIn();
      let user=googleUser.getBasicProfile()

      console.log(user)
      
    },
    turnOffFailedAuth(){
      this.failed = false
    },
    toggleLoginAndSignUp(){
      this.$store.commit("setLoginOrSignUp", !this.$store.state.loginOrSignUp);
    },
    togglePassword(){
      this.passwordType = this.passwordType == 'text' ? 'password' : 'text';
    },    
    toggleVerificationModal(){
      this.$store.commit("setShowVerificationModal", !this.$store.state.showVerificationModal);
    },    
    registerByEmail(){
      let username = this.username, email = this.email, password = this.password
      try{postRegister({username,email,password}).then(({ data: { code, result: userData, messgae,status } }) => {
        if(code == 200) {
          console.log(userData)
          this.$toast.open('You Have Successfully Registered');
          this.$store.commit("setLoginOrSignUp", !this.$store.state.loginOrSignUp);
          this.$store.commit("setShowVerificationModal", !this.$store.state.showVerificationModal);
          localStorage.setItem("user_id", userData.user_id);
          this.$store.dispatch('haveToLogin',false);
          // this.$store.commit('setAuth', userData);
          // this.$store.commit("setSidebar",userData.access);                        
          // this.$cookies.set("keepLogin", this.keepLogin ? "yes" : "no", "1y");
          // this.$cookies.set("token", userData.token, this.keepLogin ? "1y" : "1h");
          // this.$cookies.set("user_id", userData.user_id, this.keepLogin ? "1y" : "1h");
          // this.$cookies.set("role", 'user', this.keepLogin ? "1y" : "1h");
          // this.$store.dispatch('setAfterLoginPathAction','/registration')
        }else{
          this.textFailed= messgae
          this.failed = true
        }
        })
      }catch(error){

      }
    },
    userLogin(event){
      console.log(event)
      event.preventDefault();
      const { email, password } = this;      
        try{postLogin({ email, password }).then(({ data: { code, result: userData, messgae,status } }) => {
        if(code == 200) {

          this.$store.commit("setShowRegisterModal", !this.$store.state.showRegisterModal);
          localStorage.setItem("user_id", userData.user_id);
          this.$store.commit('setAuth', userData);
          if(userData.access){
          this.$store.commit("setSidebar",userData.access);}
          this.$cookies.set("keepLogin", this.keepLogin ? "yes" : "no", "1y");
          this.$cookies.set("token", userData.token, this.keepLogin ? "1y" : "1h");
          this.$cookies.set("user_id", userData.user_id, this.keepLogin ? "1y" : "1h");
          this.$cookies.set("role", userData.role, this.keepLogin ? "1y" : "1h");

          if(!localStorage.getItem('theme-color')){
            localStorage.setItem('theme-color','theme-blue');
            this.$store.commit('setTheme','theme-blue')
          }
          
          setTimeout(() => this.$router.replace(this.afterLoginPath), 500);
          clearTimeout(this.timeout);
        }else {
          if(userData.user_id){
          this.errorMessage = messgae
          this.failed = true
          this.textFailed = messgae
          this.missMatch = true
            postResendEmail({user_id: userData.user_id}).then(({ data: { code, messgae,status } }) => {});
            this.toggleVerificationModal()
          }else{
          this.errorMessage = messgae
          this.failed = true
          this.textFailed = messgae
          this.missMatch = true
          }
        }
      });}catch(error){
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
      }
    },


  },    
  computed:{
    showRegisterModal() {
      return this.$store.state.showRegisterModal;
    },
    loginOrSignUp()        {
      return this.$store.state.loginOrSignUp;
        
    },
    auth(){
      return this.$store.state.auth;
    },
    afterLoginPath(){
      console.log("after +"+this.$store.state.afterLoginPath)
      return this.$store.state.afterLoginPath;
    },
  }
}
</script>

<style>
.upload-wrapper {
  border: 2px dashed #e0e0e0;
}
.upload-wrapper .avatar-upload .rounded-circle {
  width: 200px;
  height: 200px;
}
.upload-wrapper .text-center .btn {
  margin: 0 .5rem
}
.upload-wrapper .avatar-edit-image {
  max-width: 100%
}
.upload-wrapper img {
  max-width: 100%;
}
.upload-wrapper .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}
.upload-wrapper .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
.buttons{
  color:white;
  min-width: 150px;
  background-color: #000000a1;
  height: 2.5rem;
  border-radius: 2rem;
  font-weight: 400;
  font-size: 0.8rem;
}
</style>
<style lang="scss" scope>
@import "../../../assets/sass/paper/variables.scss";
.text-blue-bold{
    color: #669DFF !important;
}
.btn-social{
    width: 500px !important;
    // max-width: 100% !important;
    border-radius: 10px !important;
    &.fb{
        background: #1877F2 !important;
        border: #1877F2 !important;
    }
    &.ggl{
        /* Auto Layout */
        background: #FFFFFF !important;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168) !important;
        border-radius: 10px;
        border: #FFFFFF !important;
        color: $matador-gray-03 !important
    }
    &.confirm{
        background: #1F548B !important;
        border: #1F548B !important;
    }
}
.text-off{
  color:#992024;
  &.error{
    font-weight: 500;
  }
}
.wrong-login {
  // width: 94%;
  height: 70px !important;
  border-radius: 5px;

  &.off {
    background-color: #F0DDDE;
  }
}
</style>