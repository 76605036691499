<template lang="pug">
transition(name="modal")
  .modal-sticky( role="document")
    #api.modal-content.modal-lg
      .modal-body.modal-lg
        .py-1.px-3.d-flex.justify-content-between
          .text.m-0(v-if="!password") You have unsaved changes!
          .text.m-0(v-if="password")  Are you sure you want to change your password? 

          div
            button.border-0.bg-transparent.py-1.px-4.text-white(style="text-transform: none;" @click="$emit('close')") Reset
            button.btn.py-1.btn-theme(style="text-transform: none;border-radius:5px" @click="$emit('confirm')") Save Changes
</template>

<script>
export default {
  data(){
    return {
    }
  },
  props: ['title', 'password','modal'],  
  mounted() {
   
  },
}
</script>

<style lang="scss">
@import "../../../assets/sass/paper/variables.scss";
@media (min-width:1030px){
   .modal-sticky{
      // margin-left:0 !important;
    position: fixed !important;
    width: 50% !important;
    z-index: 1;
    bottom:5%;
    right:20%;
  }
}
@media (min-width:768px) and (max-width:1029px) {
  .modal-sticky{
    // margin-left:0 !important;
    position: fixed !important;
    width: 80% !important;
    z-index: 1;
    bottom:5%;
    right:10%;
  }
}
@media (max-width:767px){
     .modal-sticky{
        // margin-left:0 !important;
      position: fixed !important;
      width: 90% !important;
      z-index: 1;
      bottom:5%;
      right:5%;
    }
  }
#api.modal-content{
  -webkit-box-shadow: none !important;
  box-shadow : none !important;

  .text{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color : white;
  }
  background: #232735;
  opacity: 0.8;
  border-radius: 5px;
  .btn{
  background-color: #1F548B;
}
}

</style>
