<template lang="pug">
div
  h3.mb-4.font-weight-bold(v-if="isPdf") Map
  #map(style="height: 30rem")
</template>

<script>
import Vue from 'vue';
import { getReportData, getDeviceDetail } from '../../../utils/api'
import { date } from 'yup/lib/locale';
export default {
  components: {
    
  },
  data(){
    return {
      
    }
  },
  watch: {
    reportToken: {
      immediate: true,
      handler: function(newVal){
        this.getReport()
      },
      deep: true
    }
  },
  props: ['device_id', 'dates', 'report-token', 'load', 'is-pdf',"zooms"],
  methods: {
    getReport(){
      var timeout=1000;
      if(this.zooms==10){
        timeout=15000;
      }
      getReportData({
        report_data: 'location',
        device_id: this.device_id,
        start_date: this.dates[0],
        end_date: this.dates[1]
      }).then(({ data: { result } }) => {
        setTimeout(() => this.renderMap(result.map(({ latitude: lat, longitude: lng }) => ({ lat, lng }))), timeout);
            console.log("progress 5")
            setTimeout(() => {
              this.$emit('load');
              }, 2000);
        
      });
    },
    async renderMap(lineCoordinates) {
      var bounds = new google.maps.LatLngBounds();
      let lastPosition = null;
      lineCoordinates.forEach(({ lat, lng }) => {
        lastPosition = new google.maps.LatLng(lat, lng);
        bounds.extend(lastPosition);
      });
      if(document.getElementById("map")){
        var myLatlng = new window.google.maps.LatLng(0, -180);
        var mapOptions = {
          zoom: 3,
          center: myLatlng,
          scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        };
        var map = new window.google.maps.Map(
          document.getElementById("map"),
          mapOptions
        );
        const linePath = new google.maps.Polyline({
          path: lineCoordinates,
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });

        // const image = {
        //   url: require("@/assets/img/truck.png"),
        //   size: new window.google.maps.Size(68, 152),
        //   origin: new window.google.maps.Point(0, 0),
        //   anchor: new window.google.maps.Point(0, 32),
        //   scaledSize: new google.maps.Size(17, 38),
        // };

        const image = {
          off: {
            url: require("@/assets/img/truck-off.png"),
            size: new window.google.maps.Size(30, 30),
          },
          idle: {
            url: require("@/assets/img/truck-idle.png"),
            size: new window.google.maps.Size(30, 30),
          },
          on: {
            url: require("@/assets/img/truck-on.png"),
            size: new window.google.maps.Size(30, 30),
          },
        };

        const { data } = await getDeviceDetail({ device_id: this.device_id })

        new google.maps.Marker({
          position: lastPosition,
          icon: image[data.last_status || 'idle'],
          map: map
        });

        linePath.setMap(map);
        
        setTimeout(() => {
          map.fitBounds(bounds);
          map.panToBounds(bounds);
        }, 1000)
        
      }
    },
  }
}
</script>