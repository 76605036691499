<template lang="pug">
transition(name="modal")
  .modal-mask
    .modal-wrapper
      .modal-dialog.modal-dialog-scrollable.modal-md(role="document")
        .modal-content(style="border-radius:0px !important;")
          .modal-body.ml-3.mr-3
            div(style="text-align: center !important")
              .matador-h6.matador-text-02 Confirmation
            div.mt-4
              .matador-h5.matador-text-02(style="font-weight:normal") This will redirect you to 3rd party payment service to help you with your Online Payment
            div.mt-3
              .matador-h5.matador-text-02(style="font-weight:normal")  Tap Continue to proceed with Online Payment
            div(style="margin-top:60px;text-align: center !important")
              button.border-0.bg-white.py-1.px-5.text-danger(style="text-transform: none;" @click="onCancel()") 
                .matador-h5(style="color: #1F548B !important") No, I want to do  offline payment
              matador-button.mt-3.btn.btn-primary() 
                .matador-h5.matador-text-01.pl-1.text-white(@click="onPayment()") Continue to online payment
            div.mt-4
              .matador-text-02 p.s. For Offline Payment, go to our office on:
              .matador-text-01 Company Name, Company Address
              .matador-text-02  at office hour (09.00 - 17.00 PST+7)

              
          
</template>
<script>
import { postMidtrans } from "@/utils/api";
import OfflinePaymentModal from "@/components/Matador/OfflinePaymentModal.vue";
import {getDetailInvoice} from '@/utils/api'

export default {
  data() {
    return {
    };
  },
  component: {},
  props: ["dataOrder"],
  methods: {
    onPaymentChanged(){
      this.getDataDetailInvoice();
      this.showOfflinePaymentModal =false;
      this.$toast.open('Successfully pay this order')
    },
    offlineModal(){
      this.showOfflinePaymentModal=true
    },        
    onPayment() {
      // console.log(this.dataOrder);
      postMidtrans({order_id:this.dataOrder.order_id}).then(
        ({ data: { code, result, message } }) => {
          if(code==200){
          localStorage.setItem('payment_token',result.token);            
          window.snap.pay(result.token);
          }else{
            if(localStorage.getItem('payment_token')){
            window.snap.pay(localStorage.getItem('payment_token'));
            }
          }
        }
      );
    },
    onCancel() {
      this.$emit("close");
    }
  }
};
</script>
