<script>
import { Doughnut,mixins } from 'vue-chartjs'
const { reactiveProp } = mixins;
import produce from "immer";

export default {
  extends: Doughnut,
  mixins: [reactiveProp],

  props:['chartData','options'],

  methods: {
    update() {
      // console.log(this.chartData,"doug")
      this.$data._chart.update()
      this.renderChart(this.chartData, this.options)
    },
  },

  mounted () {
    // console.log(this.chartData,"ini mounted")
    this.renderChart(this.chartData, this.options)  
  }
};
</script>
