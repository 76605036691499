<template lang="pug">
#interactive-alert-modal(:class="{'p-3': notifications.length > 0}")
  div(v-for="notif in notifications" v-if="!!auth.alert_notification")
    transition(name="fade2")
      .card(v-if="notif.auto_close_in > 0 && notif.auto_close_in <= 20")
        .card-body
          .d-flex.align-items-center
            div.mr-3
              img.alert-icon(src="@/assets/img/error-red.png") 
            h2.m-0 Alert
            a.border-none.ml-auto(
              href="javascript:;"
              role="button",
              data-dismiss="modal",
              aria-label="Close"
              @click="notif.onClose"
            )
              div
                img(:height="16" :width="16" src="@/assets/img/close.png")
          p.mt-3 {{ notif.content }}
          span(v-if="notif.auto_close_in > 0") This alert will automatically closed in {{ notif.auto_close_in }}s
</template>

<script>
import Vue from 'vue'
import { getPopupNotification } from '../../utils/api';
import { isArray, snakeCase } from 'lodash';
export default {
  data(){
    return {
      notifications: [],
    }
  },
  computed: {
    auth(){
      return this.$store.state.auth;
    }
  },
  watch: {
    auth: {
      immediate: true,
      handler: function(newVal){
        newVal && this.checkNewAlert();
      }
    }
  },
  methods: {
    checkNewAlert(){
      getPopupNotification(this.$store.state.auth.user_id).then(({ data: { result } }) => {
        this.$store.commit("setAlertCounter", result.length);
        let auto_close_duration = 20;
        const data = result.map(item => {
          item.id = snakeCase(`${item.data_notification_id} ${item.limit}`);
          return item;
        }).filter(notif => {
          return !this.$cookies.get(notif.id);
        }).map((item, index) => {
          if((index) %3 == 0 && index > 1) auto_close_duration += 10;
          item.auto_close_in = auto_close_duration;
          auto_close_duration += 2;
          item.onClose = () => {
            item.auto_close_in -= 20;
            this.$cookies.set(item.id, '1', '3h');
          }
          return item;
        });
        this.notifications = data.slice(0, 5);
        this.countDown();
      });
    },
    onShow(notif){
      // console.log(notif);
    },
    countDown(){
      this.notifications = this.notifications.map(item => {
        item.auto_close_in--;
        if(item.auto_close_in == -2) item.onClose();
        return item;
      });
      if(this.notifications.filter(item => (item.auto_close_in >= -3)).length > 0) setTimeout(() => {
        this.countDown();
      }, 1000);
    }
  },
  mounted(){
    
  }
}
</script>