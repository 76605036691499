<template lang="pug">
transition(name="modal")
  .modal-mask
    .modal-wrapper
      .modal-dialog.modal-dialog-scrollable.modal-md(role="document" style="width:440px !important;")
        .modal-content
          .modal-header
              .matador-h4.ml-3(style="color:#333333 !important;font-weight:bold !important;") Cart 
              a.bg-white.border-none(
                    href="javascript:;"
                    role="button",
                    data-dismiss="modal",
                    aria-label="Close"
                    @click="$emit('close')"
                  )
                    div.pr-3
                      img(:height="14" :width="14" src="@/assets/img/close.png")
          .modal-body(style="border-top: 3px solid #f0f0f0;")
            loader(:loading="saving")
              div(v-for="(item,index) in cartLoop" :key="index")  
                .row.pl-4.mt-4()
                  .col-1
                    .matador-h5.matador-text-02 {{++index}}.
                  .col-4
                    img.mb-2.mb-lg-0(width="100px" height="75px"  v-if="item.image" :src="item.image | assets")
                    img.mb-2.mb-lg-0(width="100px" height="75px"  v-else src="@/assets/img/car-placeholder.png")
                  .col-5
                    .row 
                      .matador-h4 {{item.name}}
                    .row.pt-3 
                      div
                        .matador-h5.pt-2 Qty (unit) :
                      div.ml-2(style="margin-top:-8px !important;width:40%")
                        //- b-form-input( type="number" v-model="item.qty" min="0" :max="item.qty")
                        .d-flex.pt-1
                          button.border-0.mt-2.mr-2(style="padding:2px 5px !important;background-color:#D3D4D8 !important;font-weight:bold;text-transform: none;border-radius:5px;" @click="subStract(item,index)" ) 
                            span.matador-text-02.mdi.mdi-18px.mdi-minus
                          .matador-h5.matador-text-01(style="padding-top:12px !important;") {{item.qty}}
                          button.border-0.mt-2.ml-2(style="padding:2px 5px !important;background-color:#D3D4D8 !important;font-weight:bold;text-transform: none;border-radius:5px;" @click="add(item,index)" )
                            span.matador-text-02.mdi.mdi-18px.mdi-plus
                  .col-2.pl-3(style="margin-top:-6px !important")
                    a.bg-white.border-none(
                        @click="showConfirmation(item)"
                      )
                      span.matador-text-danger.mdi.mdi-24px.mdi-delete-outline
                div( v-show="index != cartList.length" style="padding-bottom:20px;border-bottom:2px solid #f0f0f0;")
              div.row.ml-4(v-show="this.cartList.length !=0" style="margin-top:60px;margin-bottom:40px;")
                  matador-button.rounded-2( size="sm" style="width:92%;background-color:#6DDF92 !important;border:0px;" @click="checkOut()"  ) 
                    .matador-h5(style="font-weight:normal;") Check out now
                    span.ml-2.pt-1.pb-1.mdi.mdi-18px.mdi-arrow-right(style="")
              div.row.ml-4(v-show="this.cartList.length ==0" style="margin-left:70px !important;margin-top:30px;margin-bottom:40px;")
                  .matador-h4.ml-4(style="") You're cart is empty, now !
    confirmation-modal(
        v-if="showCancelModal"
        @close="showCancelModal = false" 
        @confirm="deleteConfirmation();" 
        title="Discard Changes"
        confirm-text="Yes"
      )
        p.m-0
          | Are you sure want to delete this cart list ?

</template>
<script>
import {getListCart, postDeleteCart,postEditCart} from '../../utils/api'
import {mapGetters} from 'vuex'
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
export default {
  data() {
    return {
      cartList:[],
      showCancelModal:false,
      cart:null,
      saving:false
    }
  },
  components:{
    ConfirmationModal
  },
  computed:{
    ...mapGetters({
      auth:'gettersAuth'
    }),
    cartLoop(){
        return this.cartList
    }
  },
  watch:{
    item:{
      handler:function (newVal) {
        // console.log(newVal.qty)
      }
    }
  },
  methods: {
    add(item,index){
      item.qty=item.qty+1;
      // console.log(item.cart_id,"ini qty nya", item.qty)
      this.editCart(item)
    },
    subStract(item,index){
      if(item.qty >0){
        item.qty=item.qty-1;
        this.editCart(item)
      }
      
    },
    async editCart(params){
      try {
        let obj ={
          cart_id:params.cart_id,
          user_id:this.auth.user_id,
          qty:params.qty
        }
        let response= await postEditCart(obj);
      } catch (error) {
        console.log(error)
      }
    },
    async getCartList(){
      try {
        this.saving=true;
        let response = await getListCart({user_id:this.auth.user_id})
        if(response.data.code == 200){
        this.cartList=response.data.result
        setTimeout(() => {
          this.saving=false;
        }, 2000);
        return this.cartList;
        }
      } catch (error) {
        
      }
    },
    showConfirmation(item){
      this.cart=item;
      this.showCancelModal=true;
    },
    checkOut(){
      this.$emit('close')
      this.$router.push('/makeOrder')
    },
    async deleteConfirmation(){
      try {
        let obj={
          cart_id:this.cart.cart_id,
          user_id:this.auth.user_id
        }
        let response = await postDeleteCart(obj)
        this.getCartList();
        this.$store.dispatch('getUserCart',this.auth.user_id)
        location.reload();
        this.showCancelModal=false;
      } catch (error) {
        this.showCancelModal=false;
        console.log("error")
      }
    }
  },
  async mounted() {
    this.getCartList();
  }
}
</script>