<template lang="pug">
.card.p-3
  .card-image(v-if='$slots.image')
    slot(name='image')
  .card-header(v-if='$slots.header || title')
    slot(name='header')
      div.h5.font-weight-bold.card-title {{title}}
      p.card-category(v-if='subTitle') {{subTitle}}
  .card-body(v-if='$slots.default')
    slot
  slot(name='raw-content')
  .card-footer(v-if='$slots.footer')
    hr
    slot(name='footer')
</template>
<script>
export default {
  name: "card",
  props: {
    title: String,
    subTitle: String
  }
};
</script>
<style>
</style>
