module.exports.google = {
  api_key: 'AIzaSyDpideuf91dtgZMGE4iYBBoBOLbw1rcGwE',
  map: {
    lat: 11.55558811377022,
    lng: 104.93367411863993,
    zoom: 12
  }
}

module.exports.app = {
  api_timeout: 10000,
  // domain: 'localhost',
  // bugsnag: false,
  // bugsnag_key: '',
  base_url: 'https://matadortracker.com',
  api_url: 'https://api.matadortracker.com',
  // api_url_v31: 'https://api.widyamatador.com/v3.1',
  socket_enabled: true, // enabled this if need to test socket listener from notifier service
  socket_url: 'wss://matadortracker.com.mm',
  socket_path: '/ws/device/socket.io',
  socket_delay: {
    update_dashboard: 30000
  },
  satelite_device_type_id: 'device_type_id-0000000000003',
  telto_device_type_id: 'device_type_id-0000000000002'
}
