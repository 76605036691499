<template lang="pug">
b-card-body.pt-2
  .col.pl-4
    div.pb-4
      p.nominal-text-box.mb-1(style="color:#505565;display:block;") Qty
      .d-flex
        b-form-input(style="display:inline;width:30%" id="Qty" type="number" v-model="quatity" min="0" :max="data.total_available") 
        span.ml-2.nominal-text-box(style="color:#505565") Units(s)
    div.pb-4
      p.nominal-text-box.mb-1(style="color:#505565;display:block;") {{planTime != 'Km' ?'Duration':'Milleage'}}
      .d-flex
        b-form-input(style="display:inline;width:30%" id="duration" type="number" v-model="duration" :disabled="durationDisabled" min="0") 
        span.ml-2.nominal-text-box(style="color:#505565") {{planTime}}(s)
    div.pb-4
      p.nominal-text-box.mb-1(style="color:#505565;display:block;") Start Date
      .d-flex
        datepicker(style="display:inline;width:60%" input-class="form-control datepicker"  @selected="onStartDate" :disabled="startDateDisabled" v-model="start_date" :value="start_date" name="startDate" ref="startDate" ) 
    div.pb-4
      p.nominal-text-box.mb-1(style="color:#505565;display:block;") Start Time
      .d-flex
        VueTimepicker(style="margin-right:11px;width:20%;height:30%" v-model="start_hour" :disabled="startHourDisabled" format="HH")
        h2(style="padding:0px !important;margin-left:15px;margin-top:-10px") :
        VueTimepicker(style="margin-left:11px;width:20%;height:30%" v-model="start_minutes" :disabled="startMinuteDisabled" format="mm")
        //- b-time(style="padding-left:0px !important;margin-top: -8px !important;" v-model="value" locale="en" @context="onContext")
    //- div.pb-4
    //-   p.nominal-text-box.mb-1(style="color:#505565;display:block;") Return by:
    //-   .d-flex
    //-     b-form-input(style="border:0px solid white;display:inline;width:60%;padding:3px !important;" v-model="end_date" id="endDate" type="date") 
    //-     //- datepicker(style="display:inline;width:60%" input-class="form-control datepicker" @selected="onEndDate" :value="end_date" name="endDate" ref="startEnd") 
    //-   .d-flex
    //-     VueTimepicker.ml-1.pt-1(style="margin-right:11px;width:20% !important;height:30%" v-model="end_hour" format="HH")
    //-     h2(style="padding:0px !important;margin-left:12px;margin-top:-6px") :
    //-     VueTimepicker.pt-1(style="border:0px solid white;margin-left:11px;width:20%t;height:30%" v-model="end_minutes" format="mm")
    
    //-ini tambahan milleage 
    div.pb-4(v-show="planTime == 'Km'")
      p.nominal-text-box.mb-1(style="color:#505565;display:block;") Return Date
      .d-flex
        datepicker(style="display:inline;width:60%" input-class="form-control datepicker" :disabled-dates="state.disabledDates"  :disabled="returnDateDisabled"  v-model="return_date" :value="return_date" name="endDate" ref="endDate")
    div.pb-4(v-show="planTime == 'Km'")
      p.nominal-text-box.mb-1(style="color:#505565;display:block;") Return Time
      .d-flex
        VueTimepicker(style="margin-right:11px;width:20%;height:30%" v-model="return_hour" :disabled="returnHourDisabled" format="HH")
        h2(style="padding:0px !important;margin-left:15px;margin-top:-10px") :
        VueTimepicker(style="margin-left:11px;width:20%;height:30%" v-model="return_minutes" :disabled="returnMinutesDisabled" format="mm")
    //- =========================================
    div.pb-4(v-show="planTime != 'Km'")
      p.nominal-text-box.mb-1(style="color:#505565;display:block;") Return By:
      .d-flex
        p(style="font-weight:normal") {{this.return_time}}
    div.pb-4
      p.nominal-text-box.mb-1(style="color:#505565;display:block;") Total Payment:
      .d-flex
        h4(style="color:#6DDF92") Rp {{payment_calculate}}
    //- button(@click="calculatePayment()") coba
        
</template>
<script>
// Main JS (in UMD format)
import VueTimepicker from 'vue2-timepicker'
// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'
import Datepicker from "vuejs-datepicker";
import dayjs from 'dayjs';
import Vue from 'vue';
import {mapGetters} from 'vuex';
import {postOrderVehicle} from '../../utils/api'
 
export default {
  props:['planTime','data','paymentMethod'],
  components:{
    Datepicker,
    VueTimepicker,
  },
  data() {
    return {  
    state : {
        disabledDates: {
          from: new Date(2021, 9, 8), // Highlight all dates after specific date
          to: new Date(2021, 9, 6), // Highlight all dates up to specific date
   
           // Highlight disabled dates
        }
      },
      quatity:null,
      duration:null,
      oldDuration:null,
      start_hour:null,
      start_minutes:null,
      return_hour:null,
      return_minutes:null,
      end_date_milleage:null,
      start_date: Vue.config.devtools ? dayjs().add(0, 'days').format('YYYY-MM-DD') : null,
      return_date:null,
      end_date:"dd/mm/yyyy hh:mm",
      optionsHour: [
        ],
      optiosMinute:[

      ],
      durationDisabled:true,
      startDateDisabled:true,
      startHourDisabled:true,
      startMinuteDisabled:true,
      returnDateDisabled:true,
      returnHourDisabled:true,
      returnMinutesDisabled:true,
      return_time:"dd/mm/yyyy hh:mm",
      payment_calculate:"0"
    }
  },
  computed:{
    ...mapGetters({
      auth:'gettersAuth'
    })
  },
  watch:{
    quatity:{
      handler:function(newVal){

        if(this.planTime!='Km'){
          if(this.startMinuteDisabled){
            this.durationDisabled=false;
          }else{
            this.calculatePayment();
            this.realtimeOrder();
          }
        }else{
          if (this.returnMinutesDisabled) {
            this.durationDisabled=false;
          }else{
            this.calculatePayment();
            this.realtimeOrder();
          }
        }

        if(newVal > this.data.total_available){
          this.quatity=this.data.total_available;
        }
      }
    },
    duration:{
      handler:function(newVal){
        if(this.planTime!='Km'){
          if(this.startMinuteDisabled){
            this.startDateDisabled=false;  
            this.calculatePayment();
          }else{
            this.calculatePayment();
            this.realtimeOrder();
          }
        }else{
          if(this.returnMinutesDisabled){
            this.startDateDisabled=false
            this.calculatePayment();
          }else{
            this.calculatePayment();
            this.realtimeOrder();
          }
        }
      }
    },
    start_date:{
      handler:function(newVal){
        this.startHourDisabled=false;
        this.return_date=this.start_date;
        if(this.planTime!='Km'){
          var date=dayjs(this.start_date).add(this.duration,'month')
          this.end_date=date;
          if(!this.startMinuteDisabled){
            this.realtimeOrder();
          }
        }else{
          //dianggap 500 mile=1hari
          let day=Math.floor(this.duration/500);
          let sisa=this.duration % 500;
          if(this.duration>500 && sisa != 0){
            day=day+1;
            var date=dayjs(this.start_date).add(day,'day');
            this.state.disabledDates.from=new Date(date);
            this.state.disabledDates.to=new Date(this.return_date);
            // console.log(this.state,"ini state apabila sisa != 0")
          }else{
            var date=dayjs(this.start_date).add(day,'day')
            this.state.disabledDates.from=new Date(date);
            this.state.disabledDates.to=new Date(this.return_date);
            // console.log(this.state,"ini state apabila sisa == 0")
          }
          if(!this.returnMinutesDisabled){
            this.realtimeOrder();
          }
        }
        
        // this.return_time=dayjs(this.end_date).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    start_hour:{
      handler:function(newVal){
        
        if(this.planTime !='Km'){
            // console.log(this.end_date)
            var date=dayjs(this.end_date).hour(newVal.HH)
            this.end_date=date;
            if (!this.startMinuteDisabled) {
              this.realtimeOrder();
            }
            
        }else{
          if(!this.returnMinutesDisabled){
            this.realtimeOrder();
          }
        }
        this.startMinuteDisabled=false
        /* if(this.startMinuteDisabled){
          if(this.planTime!='Km'){
            console.log(this.end_date)
            var date=dayjs(this.end_date).hour(newVal.HH)
            this.end_date=date;
          // this.return_time=dayjs(this.end_date).format('YYYY-MM-DD HH:mm:ss')
          }
        }else{
          if(this.planTime!='Km'){
            console.log(this.end_date)
            var date=dayjs(this.end_date).hour(newVal.HH)
            this.end_date=date;
            this.realtimeOrder();
          // this.return_time=dayjs(this.end_date).format('YYYY-MM-DD HH:mm:ss')
          }else{
            this.realtimeOrder();
          }
        }
          this.startMinuteDisabled=false
      } */
      }
    },
    start_minutes:{
      handler: function(newVal){
        if(this.planTime!='Km'){
          
          // console.log("iki",newVal)
          this.end_date=dayjs(this.end_date).minute(newVal.mm)
          this.return_time=dayjs(this.end_date).format('DD/MM/YYYY HH:mm:ss')
          this.realtimeOrder();
          this.startHourDisabled=true;
          
        }else{
          if(!this.returnMinutesDisabled){
            this.realtimeOrder()
          }
          this.startHourDisabled=true;
          this.returnDateDisabled=false;
          this.startMinuteDisabled=true;
        }
        // var date=new Date(this.start_date)
        // console.log(this.start_date,"ini")
        // console.log("ini date",date.getMonth())
        // var month=date.getMonth()+parseInt(this.duration);
        // console.log(month);
        // this.end_date= date.setMonth(month)  
        // console.log(this.end_date)
      }
    },
    return_date:{
      handler:function(newVal){
        if(!this.returnMinutesDisabled){
            this.realtimeOrder()
          }
        this.returnHourDisabled=false;
      }
    },
    return_hour:{
      handler:function(newVal){
        if(!this.returnMinutesDisabled){
            this.realtimeOrder()
          }
        this.returnDateDisabled=true
        this.returnMinutesDisabled=false;
      }
    },
    return_minutes:{
      handler:function(newVal){
        this.returnHourDisabled=true;  
        //panggil fungsi realtimeOrder
        this.realtimeOrder();
        this.returnMinutesDisabled=false;  
      }
    }
  },
  methods: {
    onStartDate(val){
      this.start_date = val;
    },
    
    async realtimeOrder(){
      var time=new Date(this.start_date);
      console.log("ini time",time)
      var tahun= time.getFullYear();
      var bulan= time.getMonth();
      var tanggal= time.getDate();
      var gabung= tahun+"-"+bulan+"-"+tanggal;
      console.log("gabung",gabung);
      if(this.planTime=='Km'){
        var returnTime=new Date(this.return_date);
        console.log("ini time",time)
        var returnTahun= returnTime.getFullYear();
        var returnBulan= returnTime.getMonth();
        var returnTanggal= returnTime.getDate();
        var returnGabung= returnTahun+"-"+returnBulan+"-"+returnTanggal;
      }
      var obj={
        etalase_id:this.data.etalase_id,
        payment_method:this.paymentMethod,
        user_id:this.auth.user_id,
        start:gabung+" "+this.start_hour.HH+":"+this.start_minutes.mm+":"+ "00",
        end:this.planTime!='Km'?dayjs(this.end_date).format('YYYY-MM-DD HH:mm:ss'):returnGabung+" "+this.return_hour.HH+":"+this.return_minutes.mm+":"+ "00",
        qty:this.quatity,
        duration:this.duration,
        payment_plan:null,
        price:this.payment_calculate,
      }
      
      await this.$emit('submitData',obj) 
      console.log(obj,"ini obj")
      
    
    },
    calculatePayment(){
      let resultPayment;
      if(this.paymentMethod=="monthly"){
        resultPayment=this.data.price_per_month * this.duration * this.quatity;
        this.payment_calculate= this.mataUang(resultPayment);
      }else if(this.paymentMethod=="daily"){
        resultPayment=this.data.price_per_day * this.duration * this.quatity;
        this.payment_calculate= this.mataUang(resultPayment);
      }else if(this.paymentMethod=="hourly"){
        resultPayment=this.data.price_per_hour * this.duration * this.quatity;
        this.payment_calculate= this.mataUang(resultPayment);
      }else{
          resultPayment=this.data.price_per_km * this.duration * this.quatity;
          this.payment_calculate=this.mataUang(resultPayment);
      }
    },
    mataUang(req){
      var	number_string = req.toString();
      var sisa 	= number_string.length % 3
      var rupiah 	= number_string.substr(0, sisa)
      var ribuan 	= number_string.substr(sisa).match(/\d{3}/g)
          
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
      return rupiah;
    },
  }
}
</script>
<style>
.b-time .b-time-ampm{
  margin-left:3px;
}
.b-time .b-time-header output {
    padding: 0rem;
    display: none;
}
.form-control{
  padding-left:0px !important;
}
div.b-form-spinbutton.form-control.d-inline-flex.flex-column.b-time-hours{
  padding: 0px !important;
}
div.b-form-spinbutton.form-control.d-inline-flex.flex-column.b-time-minutes{
  padding: 0px !important;
}
div.b-form-spinbutton.form-control.d-inline-flex.flex-column.b-time-ampm{
  padding: 0px !important;
}
button.btn.btn-sm.border-0.rounded-0{
  background-color: white;
  color: black;
}
.vue__time-picker input.display-time{
  width: 69px;
  height: 32px;
  background: #FFFFFF;
/* BG / 04 */

border: 1px solid #D3D4D8;
box-sizing: border-box;
border-radius: 5px;
}
</style>