<template>
  <component :is="tag"
             class="dropdown"
             :class="{show:isOpen}"
             @click="toggleDropDown"
             v-click-outside="closeDropDown">
    <a class="btn-rotate"
       :class="titleClasses" id="dropdownMenuButton"
       data-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false">
      <slot name="title">
        <i :class="icon"></i>
        <span class="notification">{{title}}
          <b class="caret"></b>
        </span>
      </slot>
    </a>
    <ul class="dropdown-menu" style="" :class="{show:isOpen}" aria-labelledby="dropdownMenuButton">
      <slot></slot>
    </ul>
  </component>
</template>
<script>import jQuery from 'jquery'

export default {
  
  props: {
    tag: {
      type: String,
      default: "li"
    },
    title: String,
    icon: String,
    titleClasses: [String, Object, Array]
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
      this.$emit("change", this.isOpen);
    },
    closeDropDown() {
      this.isOpen = false;
      this.$emit('change', false);
    }
  },
  mounted() {
  }
};
</script>
<style >
  .dropdown-menu{
    width:15vw;
    transform: translate3d(12px, -20px, 0px) !important;
    position: absolute !important;
    
  }
</style>