<template lang="pug">
transition(name="modal")
  .modal-mask
    .modal-wrapper
      .modal-dialog.modal-dialog-scrollable.modal-lg(role="document")
        .modal-content
          .modal-header
              .matador-h4.ml-3(style="color:#333333;font-weight: bold;font-size: 18px;") Rent this vehicle
              a.bg-white.border-none(
                    href="javascript:;"
                    role="button",
                    data-dismiss="modal",
                    aria-label="Close"
                    @click="$emit('close')"
                  )
                    div
                      img(:height="14" :width="14" src="@/assets/img/close.png")
          .modal-body(style="border-top: 3px solid #f0f0f0;")
            loader(:loading="saving")
              div.row
                .col-6.profile-rent
                  div
                    img.mb-2.mb-lg-0(width="100%" style="border-top-right-radius: 1px;border-top-left-radius: 1px;" v-if="dataVehicle.image" :src="dataVehicle.image | assets")
                    img.mb-2.mb-lg-0(width="100%" style="border-top-right-radius: 1px;border-top-left-radius: 1px;" v-else src="@/assets/img/car-placeholder.png")
                .col-6.detail-rent
                  div
                    .matador-h5 {{dataVehicle.name}} {{dataVehicle.series}}
                    .matador-text-03 {{dataVehicle.description}} 
                  div(style="margin-top:10px;")
                    .matador-h5 Rent Fee
                    div.row
                      .col-lg-4.col-md-6.col-sm-12.col-xs-12
                        .matador-text-03 Per Month
                      .col-lg-5.col-md-6.col-sm-12.col-xs-12
                        .matador-text-02(style="text-align:right") Rp {{this.mataUang(dataVehicle.price_per_month)}}
                    div.row
                      .col-lg-4.col-md-6.col-sm-12.col-xs-12
                        .matador-text-03 Per Day
                      .col-lg-5.col-md-6.col-sm-12.col-xs-12
                        .matador-text-02(style="text-align:right") Rp {{this.mataUang(dataVehicle.price_per_day)}}
                    div.row
                      .col-lg-4.col-md-6.col-sm-12.col-xs-12
                        .matador-text-03 Per Hour
                      .col-lg-5.col-md-6.col-sm-12.col-xs-12
                        .matador-text-02(style="text-align:right") Rp {{this.mataUang(dataVehicle.price_per_hour)}}
                    div.row
                      .col-lg-4.col-md-6.col-sm-12.col-xs-12
                        .matador-text-03 Per km
                      .col-lg-5.col-md-6.col-sm-12.col-xs-12
                        .matador-text-02(style="text-align:right") Rp {{this.mataUang(dataVehicle.price_per_km)}}
                    div(style="margin-top:10px;")
                      .matador-h5 Total Available Vehicle : {{dataVehicle.total_available}} Units
                    div.row(style="margin-top:94px")
                      div
                        .matador-h5 Quantity (unit) :
                      div.ml-1(style="width:20%;margin-top:-8px !important")
                        b-form-input(type="number" v-model="unit" min="1" :max="dataVehicle.total_available")
                      div
                        p.text-danger(v-show="this.error.length>0") {{error}}
                    div.row.mt-4
                      matador-button.rounded-2( size="sm" style="background-color:#669DFF !important;width:90%;border:0px;" @click="addCart()" :disabled="allowPost" icon="mdi mdi-24px mdi-cart-plus")
                        span.matador-h4.ml-2 Add to Cart
                    div.row.mt-3
                      matador-button.rounded-2( size="sm" style="width:90%;background-color:#6DDF92 !important;border:0px;" @click="checkOut()" :disabled="allowPost" icon="mdi mdi-24px mdi-check-circle-outline") 
                        span.matador-h4.ml-2 Check out now
    confirmation-modal(
        v-if="showCancelModal"
        @close="showCancelModal = false" 
        @confirm="$emit('close');" 
        title="Discard Changes"
        confirm-text="Yes"
      )
        p.m-0
          | Are you sure want to discard changes you made ?
</template>
<script>
import RentalPlanCard from '@/components/Matador/RentalPlanCard';
import {postOrderVehicle,postAddCart} from '../../utils/api'
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import {mapGetters} from 'vuex';
export default {
  props:['dataVehicle'],
  data() {
    return {
      unit:null,
      errorMessage:false,
      error:"",
      allowPost:true,
      selectedTime:null,
      selectedPayment:null,
      saveData:{
        monthly:[],
        daily:[],
        hourly:[],
        mileage:[]
      },
      timeData:false,
      payment_price:null,
      saving:false,
      showCancelModal:false,
      displayPriceMonthly:"xx.000.000",
      displayPriceDaily:"xx.000.000",
      displayPriceHourly:"xx.000.000",
      displayPriceMileage:"xx.000.000",
    }
  },
  components:{
    RentalPlanCard,ConfirmationModal
  },
  watch:{
    unit:{
      handler:function(newVal){
        if(newVal > this.dataVehicle.total_available){
          
          this.errorMessage=true
          // console.log(newVal,this.errorMessage)
          this.error="Total available vehicle less than your input"
          this.allowPost=true
        }else if(newVal == 0 ){
          this.error="You have to rent vehicle more than 0 Unit"
          this.allowPost=true
        }else{
          this.error=""
          this.allowPost=false
        }
          
      }
    },
    selectedTime:{
      handler:function(newVal){
        this.selectedTime=newVal;
        // console.log(this.selectedTime)
      }
    },
    selectedPayment:{
      handler:function(newVal){
        this.selectedPayment=newVal;
        // console.log(this.selectedPayment)
      }
    }
  },
  computed:{
    allowPayment(){
      const {
        payment_method,
        start,
        end,
        qty,
        duration,
      } = this.timeData;
      return !!payment_method && !!start && !!end && !!qty && !!duration;
    },
    ...mapGetters({
      auth:'gettersAuth'
    })
  },
  methods: {
    //ini pembaruan
    mataUang(req){
      var	number_string = req.toString();
      var sisa 	= number_string.length % 3
      var rupiah 	= number_string.substr(0, sisa)
      var ribuan 	= number_string.substr(sisa).match(/\d{3}/g)
          
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
      return rupiah;
    },
    async addCart(){
      try {
        if(this.auth.role != "guest"){
          this.saving=true;  
        let obj={
          etalase_id:this.dataVehicle.etalase_id,
          qty:this.unit,
          user_id:this.auth.user_id
        }
        let response= await postAddCart(obj);
          if (response.data.code == 200 && response.data.message == "Add Cart success"){
            this.saving = false;
            this.$store.dispatch('getUserCart',this.auth.user_id)
            this.$emit('success',"success"); 
          }else{
            this.saving = false;
            this.$emit('success',"failed"); 
          }
        }else{
          this.$router.push('/login')
        }
      } catch (error) {
        this.saving = false;
        this.$emit('success',"failed"); 
      }
    },
    async checkOut(){
      try {
      if(this.auth.role != 'guest'){

        this.saving=true;  
        let obj={
          etalase_id:this.dataVehicle.etalase_id,
          qty:this.unit,
          user_id:this.auth.user_id,
          dataVehicle:this.dataVehicle
        }
            await this.$store.dispatch('redirectUserCheckout',obj) 
            this.$emit('success',"checkout"); 
            setTimeout(() => {
              this.saving = false;
              this.$router.push('/makeOrderRedirect');
            }, 1000);
        }else{
          this.$store.dispatch("haveToLogin",true);
          this.$router.push('/login');
        }
      } catch (error) {
        this.saving = false;
        this.$emit('success',"failed"); 
      }
    },
    //ini batas pembaruan
    async onCancel(){
      this.showCancelModal = true;
    },
    async submitOrder(){
      // this.saving=true;
      // this.timeData.payment_plan=this.selectedPayment;
      // this.timeData.payment_method=this.selectedTime;
      // let postData={
      //   etalase_id:this.timeData.etalase_id,
      //   payment_method:this.timeData.payment_method,
      //   user_id:this.timeData.user_id,
      //   start:this.timeData.start,
      //   end:this.timeData.end,
      //   qty:this.timeData.qty,
      //   duration:this.timeData.duration,
      //   payment_plan:this.timeData.payment_plan,
      // }
      // try {
      //   console.log(postData,"ini postdata")
      //   let response=await postOrderVehicle(postData);
      //   console.log(response,"ini");
      //   if(response.data.code == 200){
      //     console.log(response)
      //     this.saving = false;
      //     this.$emit('success'); 
      //   }
      // } catch (error) {
      //   console.log(error)
      // }
      this.saving=true;
      if(this.selectedTime == 'monthly'){
        this.saveData.monthly[this.saveData.monthly.length-1].payment_plan=this.selectedPayment;
        var post=this.postDataFunction(
          this.saveData.monthly[this.saveData.monthly.length-1].etalase_id,
          this.saveData.monthly[this.saveData.monthly.length-1].payment_method,
          this.saveData.monthly[this.saveData.monthly.length-1].user_id,
          this.saveData.monthly[this.saveData.monthly.length-1].start,
          this.saveData.monthly[this.saveData.monthly.length-1].end,
          this.saveData.monthly[this.saveData.monthly.length-1].qty,
          this.saveData.monthly[this.saveData.monthly.length-1].duration,
          this.saveData.monthly[this.saveData.monthly.length-1].payment_plan,
        )
        // console.log("ini data siap untuk masuk api",post)
      }else if(this.selectedTime == 'daily'){
        this.saveData.daily[this.saveData.daily.length-1].payment_plan=this.selectedPayment;
        var post=this.postDataFunction(
          this.saveData.daily[this.saveData.daily.length-1].etalase_id,
          this.saveData.daily[this.saveData.daily.length-1].payment_method,
          this.saveData.daily[this.saveData.daily.length-1].user_id,
          this.saveData.daily[this.saveData.daily.length-1].start,
          this.saveData.daily[this.saveData.daily.length-1].end,
          this.saveData.daily[this.saveData.daily.length-1].qty,
          this.saveData.daily[this.saveData.daily.length-1].duration,
          this.saveData.daily[this.saveData.daily.length-1].payment_plan,
        )
        // console.log("ini data siap untuk masuk api",post)
      }else if(this.selectedTime == 'hourly'){
        this.saveData.hourly[this.saveData.hourly.length-1].payment_plan=this.selectedPayment;
        var post=this.postDataFunction(
          this.saveData.hourly[this.saveData.hourly.length-1].etalase_id,
          this.saveData.hourly[this.saveData.hourly.length-1].payment_method,
          this.saveData.hourly[this.saveData.hourly.length-1].user_id,
          this.saveData.hourly[this.saveData.hourly.length-1].start,
          this.saveData.hourly[this.saveData.hourly.length-1].end,
          this.saveData.hourly[this.saveData.hourly.length-1].qty,
          this.saveData.hourly[this.saveData.hourly.length-1].duration,
          this.saveData.hourly[this.saveData.hourly.length-1].payment_plan,
        )
        // console.log("ini data siap untuk masuk api",post)
      }else{
        this.saveData.mileage[this.saveData.mileage.length-1].payment_plan=this.selectedPayment;
        var post=this.postDataFunction(
          this.saveData.mileage[this.saveData.mileage.length-1].etalase_id,
          this.saveData.mileage[this.saveData.mileage.length-1].payment_method,
          this.saveData.mileage[this.saveData.mileage.length-1].user_id,
          this.saveData.mileage[this.saveData.mileage.length-1].start,
          this.saveData.mileage[this.saveData.mileage.length-1].end,
          this.saveData.mileage[this.saveData.mileage.length-1].qty,
          this.saveData.mileage[this.saveData.mileage.length-1].duration,
          this.saveData.mileage[this.saveData.mileage.length-1].payment_plandaily
        )
        // console.log("ini data siap untuk masuk api",post)
      }
      this.postToApi(post)
      
    },
    postDataFunction(etalase_id,payment_method,user_id,start,end,qty,duration,payment_plan){
      let postData={
        etalase_id,
        payment_method,
        user_id,
        start,
        end,
        qty,
        duration,
        payment_plan
      }
      return postData;
    },
    async postToApi(post){
      try {
        // console.log(post,"ini postdata")
        let response=await postOrderVehicle(post);
        // console.log(response,"ini");
        if(response.data.code == 200){
          this.saving = false;
          this.$emit('success'); 
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  async mounted() {
    this.$store.dispatch('getUserCart',this.auth.user_id)
  },
}
</script>
<style>
.modal-lg{
  max-width: 880px;
}
/* .custom-radio .custom-control-input:checked ~ .custom-control-label::after{
  background-color: #6DDF92;
  border-radius: 10px;
} */
</style>
