<template lang="pug">
.border.chart-card.p-3
  slot(name='title')
    | {{title}}
  p.card-category
    slot(name='subTitle')
      | {{subTitle}}
  div
    slot(name='chart')
    .footer
      .chart-legend.w-100
        slot(name='legend')

</template>
<script>
import Card from "./Card.vue";
export default {
  name: "chart-card",
  components: {
    Card
  },
  props: {
    footerText: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      chartId: "no-id"
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>
<style>
.chart-card {
  border-radius: 1rem !important;
}
</style>
