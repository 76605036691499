<template lang="pug">
transition(name="modal")
  .modal-mask
    .modal-wrapper
      .modal-dialog.modal-dialog-scrollable.modal-lg(role="document")
        .modal-content
          .modal-body
            
</template>

<script>
export default {
  data(){
    return {
      modalTabIndex: 0,
      cols: 2,
    }
  },
  
  computed: {
    vehicle_data() {
      if (!this.vehicle || !this.vehicle.vin) return [];
      const log = this.vehicle.device_unit.device.last_log_data || {};
      return [
        ["VIN", this.vehicle.vin],
        ["Device Name", this.vehicle.unit_id],
        ["Vehicle Type", this.vehicle.unit_type.vehicle_type.name],
        [
          "Current Location", log.location_name
          // log.latitude ? log.latitude +
          //   " , " +
          //   log.longitude : "",
        ],
      ];
    },
    tabs() {
      if (!this.vehicle || !this.vehicle.vin) return [];
      const log = this.vehicle.device_unit.device.last_log_data || {};
      let current_info = [];
      if(this.modalTabIndex == 0) {
        current_info= [
          ["Vin", this.vehicle.vin],
          ["Coordinate", log.latitude ? log.latitude + " , " + log.longitude : ""],
          ["Location", log.location_name],
          ["Power Status", log.power_status ? "On" : "Off"],
          ["Operating Status", log.operate_status ? "On" : "Off"],
          ["UUID", this.vehicle.device_unit.device_id],
          ["Speed",  `${log.speed} km/h`],
          ["Today Idle Time", `${this.vehicle.device_unit.idle_time} s`],
          ["Today Operating Time", `${this.vehicle.device_unit.opt_time} s` ],
          ["Today Operating Mileage", `${this.vehicle.device_unit.today_mileage} km` ],
          ["Total Time", `${log.total_time} s`],
          ["Total Mileage (odometer)", `${log.total_mileage} km`],
          ["Total Operating Time", `${log.operate_time} s`],
          ["Total Operating Mileage", `${log.operate_mileage} km`],
        ]
      } else if (this.modalTabIndex == 1) {
        current_info= [
          ["Voltmeter", log.voltage],
          ["Start Stop Counter", log.start_count],
          ["Engine", this.vehicle.unit_type.engine],
          ["Tire Count", this.vehicle.unit_type.tire_count],
          ["Torque", this.vehicle.unit_type.torque],
          ["Brand", this.vehicle.unit_type.brand_id],
          ["Series", this.vehicle.unit_type.series],
          ["Year", this.vehicle.unit_type.year],
          ["Fuel", this.vehicle.unit_type.fuel],
        ]
      } else if (this.modalTabIndex == 2) {
        current_info= [
          ["GSM Signal", log.signal],
          ["IMEI", log.imei],
          ["Carrier/Mobile Network", this.vehicle.device_unit.device.network_provider_id],
          ["SIM Number", this.vehicle.device_unit.device.sim_number],
          ["Description", this.vehicle.device_unit.device.description],
          ["Data Usage", log.data_usage + " MB"],
          ["Device Temperature", log.temperature + " C"]
        ]
      }
      let columns = [];
      let mid = Math.ceil(current_info.length / this.cols);
      for (let col = 0; col < this.cols; col++) {
        columns.push(current_info.slice(col * mid, col * mid + mid));
      }
      return columns;
    }
  }
}
</script>