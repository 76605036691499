<template lang="pug">
transition(name="modal")
  .modal-mask(@click="onModalClick")
    #geo-form.modal-wrapper
      .modal-dialog(style="width: 1180px;max-width: 95%;" role="document")
        .modal-content
          .modal-body.p-0
            google-map#map(
              ref="geofenceMap"
              :zoom="12"
              :options="mapOptions"
            )
              google-map-polygon(
                :paths="lines[0].path",
                :strokeColor="lines[0].strokeColor"
                :fillColor="lines[0].fillColor"
                :fillOpacity="0.7"
              )
              google-map-marker(
                v-for="(marker, index) in markersList"
                :key="index"
                :title="marker.title"
                :position="marker.position"
                :icon="marker.icon"
              )
              //- google-map-marker(
              //-   v-for="(item, index) in lines[0].path"
              //-   :key="index"
              //-   :position="item"
              //- )
            .buttons(v-if="!drawMode && !geofence")
              button.btn.btn-light.btn-add(@click="drawMode = true")
                img(:height="16" :width="16" src="@/assets/img/pen.png")
                span Add Geofence
              //- button.btn.btn-danger.btn-cancel(@click="$emit('close')")
                span Cancel
            .buttons(v-if="drawMode && lines[0].path.length >= 3 && !showForm && !geofence")
              button.btn.btn-danger.btn-cancel(@click="lines[0].path = []")
                span Clear Pointer
              button.btn.btn-success.btn-cancel(@click="showForm = true")
                h4.my-0 ✓
                span {{ geofence ? 'Confirm Geofence' : 'Add Geofence' }}
            geofence-form(
              :visible="showForm" 
              :colors="colors" 
              @switchColor="onSwitchColor" 
              @cancel="showForm = false" 
              :color="lines[0].fillColor" 
              :paths="lines[0].path"
              :geofence="geofence"
            )
            button.btn.btn-danger.btn-add-device(@click="onAddDevice" v-if="!!geofence && !showForm")
              img.mr-1(:width="18" style="margin-top: -3px" src="@/assets/img/plus-white.png")
              span Add Device
            button.close-map(@click="$emit('close')" v-if="!geofence")
              img(:height="20" :width="20" src="@/assets/img/close.png")
</template>

<script>

import GeofenceForm from './GeofenceForm.vue';

const colors = [
  'rgba(65, 131, 215, 0.4)',
  'rgba(235, 59, 145, 0.4)',
  'rgba(103, 200, 77, 0.5)',
  'rgba(67, 19, 125, 0.35)',
  'rgba(241, 145, 53, 0.45)'
];

const lines = [{
  path: [],
  geodesic: true,
  strokeColor: colors[1],
  fillColor: colors[1],
}]

export default {
  data(){
    return {
      lines,
      colors,
      drawMode: false,
      showForm: false,
      markersList: []
    }
  },
  components: {
    GeofenceForm
  },
  watch: {
    geofence: {
      immediate: true,
      handler: function(val){
        if(!!val){
          val.device.forEach(item => {
            item.last_log[0] && this.markersList.push({
              title: item.device_name,
              description: item.device_name,
              position: {
                lat: item.last_log[0].latitude,
                lng: item.last_log[0].longitude,
              },
              // icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/library_maps.png",
              icon: require("../../../assets/img/truck-sm.png")
            })
          });
          this.lines[0].path = val.coordinate[0].map(([lat, lng]) => ({ lat, lng }));
          this.lines[0].fillColor = val.color;
          this.lines[0].strokeColor = val.color;
          (() => {
            var bounds = new google.maps.LatLngBounds();
            var i;
            var polygonCoords = this.lines[0].path.map(({ lat, lng }) => new google.maps.LatLng(lat, lng));

            for (i = 0; i < polygonCoords.length; i++) {
              bounds.extend(polygonCoords[i]);
            }
            ([500, 1250, 2250]).forEach((ms) => {
              setTimeout(() => {
                try {
                  this.$refs.geofenceMap.$_getMap().then((map) => {
                    map.setCenter({lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng()})
                    map.fitBounds(bounds);
                  });
                } catch (error) {
                  
                }
              }, ms);
            });
          })();
          // console.log(this.lines[0].path);
        } else {
          this.lines[0].path = [];
        }
      }
    }
  },
  computed: {
    mapOptions(){
      return {
        draggable: true,
        fullscreenControl: false,
        draggableCursor: this.drawMode && !this.showForm ? "url(/icons/plus-thin.png) 12 12, auto" : null,
        // draggableCursor : "url(/icons/plus-thin.png), auto",
      }
    }
  },
  props: ['title', 'confirmText', 'geofence'],
  methods: {
    initMap(){
      setTimeout(() => {
        try {
          this.$refs.geofenceMap.$_getMap().then((map) => {
            window.google.maps.event.addListener(map, 'click', ({ latLng: { lat, lng } }) => {
              this.drawMode && !this.showForm && this.lines[0].path.push({lat: lat(), lng: lng()});
            })
          });
        } catch (error) {
          
        }
      }, 3000)
    },
    onSwitchColor(color){
      this.lines[0].strokeColor = color;
      this.lines[0].fillColor = color;
    },
    onAddDevice(){
      this.showForm = true;
    },
    onModalClick(event){
      if(event.target.id === 'geo-form'){
        this.$emit('close');
      }
    }
  },
  mounted(){
    this.initMap();
    // this.lines[0].path = [];
    // if(this.$store.state.devMode){
    //   this.lines[0].path = [{"lat":11.50104957763202,"lng":104.78493854536131},{"lat":11.519888981935177,"lng":104.91471454633788},{"lat":11.421977642290203,"lng":104.96827289594725},{"lat":11.405824061929819,"lng":104.84055683149413},{"lat":11.442504822927269,"lng":104.76090595258788}];
    //   this.drawMode = true;
    //   this.showForm = true;
    // }
  }
}
</script>

<style lang="scss">
#geo-form {
  .modal-content {
    border-radius: 10px;
    .modal-body {
      min-height: 200px;
      position: relative;
      overflow: hidden;
      #map {
        height: 600px;
        max-height: 95%;
        .map-view {
          border-radius: 10px;
        }
      }
      .buttons {
        position: absolute;
        bottom: 15px;
        width: 320px;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 50%;
        margin-left: -160px;
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 25px;
          padding-right: 25px;
          img, h4 {
            margin-right: 5px;
          }
          margin: 0 5px;
          &.btn-danger, &.btn-success {
            border-width: 0;
          }
        }
      }
      .close-map {
        position: absolute;
        top: 7px;
        right: 10px;
        border: none;
        background-color: transparent;
        padding: .5rem;
      }
      .btn-add-device {
        position: absolute;
        top: 17px;
        right: 20px;
      }
    }
  }
}
</style>