<template lang="pug">
div
    //- loader(:loading="loading && !chartData")
    .py-3.px-4.d-flex.justify-content-center.align-items-center(v-if='!chartData') There are no records to show
    h3.mb-4.font-weight-bold(v-if="isPdf") Graph
    div(v-if="chartData")
      .row
        .col-md-4
          .border.p-4.mb-4
            h4.h5.mt-0.mb-3 Speed (Km/H)
            bar-chart(:class="{'mb-5': !isPdf}" :minimum="getMinimum('speed', 'data')" :chart-data="barChart('speed', 'data')" :options="barChart('speed', 'options')" :stacked="true" :height="240" :width="null")
        .col-md-4
          .border.p-4.mb-4
            h4.h5.mt-0.mb-3 Operate Time (H)
            bar-chart(:class="{'mb-5': !isPdf}" :minimum="getMinimum('operate_time', 'data')" :chart-data="barChart('operate_time', 'data')" :options="barChart('operate_time', 'options')" :stacked="true" :height="240" :width="null")
        //- .page-break(v-if="isPdf")
        //- .row
        .col-md-4
          .border.p-4.mb-4
            h4.h5.mt-0.mb-3 Idle Time (H)
            bar-chart(:class="{'mb-5': !isPdf}" :minimum="getMinimum('idle_time', 'data')" :chart-data="barChart('idle_time', 'data')" :options="barChart('idle_time', 'options')" :stacked="true" :height="240" :width="null")
        .col-md-4
          .border.p-4.mb-4
            h4.h5.mt-0.mb-3 Operate Mileage (Km)
            bar-chart(:class="{'mb-5': !isPdf}" :minimum="getMinimum('operate_mileage', 'data')" :chart-data="barChart('operate_mileage', 'data')" :options="barChart('operate_mileage', 'options')" :stacked="true" :height="240" :width="null")
        .col-md-4
          .border.p-4.mb-4
            h4.h5.mt-0.mb-3 Temperature (℃)
            line-chart(:chart-data="lineChart('temperature', 'data')" :options="lineChart('temperature', 'options')" :stacked="true" :height="280" :width="null" :gradients='["rgba(238, 201, 69, 1)", "rgba(241, 246, 255, 1)"]')
        .col-md-4
          .border.p-4.mb-4
            h4.h5.mt-0.mb-3 Fuel Consumtions (L)
            bar-chart(:class="{'mb-5': !isPdf}" :minimum="getMinimum('fuel', 'data')" :chart-data="barChart('fuel', 'data')" :options="barChart('fuel', 'options')" :stacked="true" :height="240" :width="null")

</template>
<script>
import Vue from 'vue';
import BarChart from "@/components/Chartjs/BarChart";
import LineChart from "@/components/Chartjs/LineChart";
import dayjs from "dayjs";
import { getReportData } from '../../../utils/api'
import Api from '@/utils/api'

export default {
  components: {
    BarChart,
    LineChart
  },
  data(){
    return {
      loading: false,
      chartData: null,
    }
  },
  watch: {
    reportToken: {
      immediate: true,
      handler: function(newVal){
        this.getReport()
      },
      deep: true
    }
  },
  props: ['device_id', 'dates', 'report-token', 'load', 'is-pdf'],
  methods: {
    
    getMinimum(field, type) {
      const bar = this.barChart(field, type).datasets[0]
      if (!bar) return 0

      return bar.minimum
    },

    lineChart(prop, key){
      const chartData = this.chartData[prop];
      if(typeof chartData == 'object' && typeof chartData[0] == 'object'){
        const datasets = [];
        var divider=1;
        if(prop == "total_time"){
          divider=3600;
        }else if(prop == "operate_time"){
          divider=3600;
        }else if(prop == "total_mileage"){
          divider=1000
        }else if(prop == "idle_time"){
          divider=1000
        }
        if(typeof chartData[0].total != 'undefined'){
          datasets.push({
            label: "Total",
            data: chartData.map(item => parseInt(item.total)/divider),
            minimum: Math.min.apply(null, chartData.map(item => parseInt(item.total)/divider)),
            stack: "speed-stack",
          });
        }
        if(typeof chartData[0].average != 'undefined'){
          datasets.push({
            label: "Average",
            backgroundColor: "#7998B9",
            data: chartData.map(item => parseInt(item.average)/divider),
            minimum: Math.min.apply(null, chartData.map(item => parseInt(item.average)/divider)),
            stack: "speed-stack",
          });
        }
        if(typeof chartData[0].top != 'undefined'){
          datasets.push({
            label: "Top",
            backgroundColor: "#FDCA40",
            data: chartData.map(item => parseInt(item.top - (item.average || 0))/divider),
            minimum: Math.min.apply(null, chartData.map(item => parseInt(item.top - (item.average || 0))/divider)),
            stack: "speed-stack",
          });
        }
        return {
          data: {
            labels: chartData.map(item => dayjs(item.label).format('DD MMM YY')),
            datasets,
          },
          options: {
            elements: {
              line: {
                tension: 0,
              },
            },
            legend: {
              display: true,
            }
          },
        }[key];
      } else {
        return {
          data: {
            labels: [],
            datasets: []
          },
          options: {

          }
        }[key];
      }
    },
    barChart(prop, key){
      const chartData = this.chartData[prop];
        var divider=1;
        if(prop == "total_time"){
          divider=3600;
        }else if(prop == "operate_time"){
          divider=3600;
        }else if(prop == "total_mileage"){
          divider=1000
        }else if(prop == "idle_time"){
          divider=3600
        }else{
          divider=1;
        }
      if(typeof chartData == 'object' && typeof chartData[0] == 'object'){
        const datasets = [];
        if(typeof chartData[0].total != 'undefined'){
          datasets.push({
            label: "Total",
            backgroundColor: "#7998B9",
            data: chartData.map(item => parseInt(item.total)/divider),
            minimum: Math.min.apply(null, chartData.map(item => parseInt(item.total)/divider)),
            stack: "speed-stack",
          });
        }
        if(typeof chartData[0].average != 'undefined'){
          datasets.push({
            label: "Average",
            backgroundColor: "#7998B9",
            data: chartData.map(item => parseInt(item.average)/divider),
            minimum: Math.min.apply(null, chartData.map(item => parseInt(item.average)/divider)),
            stack: "speed-stack",
          });
        }
        if(typeof chartData[0].top != 'undefined'){
          datasets.push({
            label: "Top",
            backgroundColor: "#FDCA40",
            data: chartData.map(item => parseInt(item.top - (item.average || 0))/divider),
            minimum: Math.min.apply(null, chartData.map(item => parseInt(item.top - (item.average || 0))/divider)),
            stack: "speed-stack",
          });
        }
        return {
          data: {
            labels: chartData.map(item => dayjs(item.label).format('DD MMM YY')),
            datasets,
          },
          options: {
            tooltips: { enabled: false },
            hover: { mode: null },
            legend: {
              onClick: (e) => e.stopPropagation(),
            },
            scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: false
                    }
                }]
            },
            // yAxes: [{
            //   display: true,
            //   ticks: {
            //     // suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
            //     // OR //
            //     beginAtZero: false   // minimum value will be 0.
            //   }
            // }]
          },
        }[key];
      } else {
        return {
          data: {
            labels: [],
            datasets: []
          },
          options: {}
        }[key];
      }
    },

    getReport(){
      this.chartData = null
      this.loading = true;

      let [start_date, end_date] = this.dates
      let device_id = this.device_id

      const responeCollector = response => {
        let data = response.data.result.data
        this.chartData = data;
        this.$emit('load');
      }

      let payload = {
        report_type: "graph",
        start_date,
        end_date
      }
      
      Api.device.report(device_id, payload)
        .then(responeCollector)
        .catch(e => {})
        .finally(() => this.loading = false)



      // const {
      //   device_id,
      //   dates: [
      //     start_date,
      //     end_date
      //   ]
      // } = this;
      // if(device_id && start_date){
      //   this.loading = true;
      //   getReportData({
      //     report_data: 'graph',
      //     device_id,
      //     start_date,
      //     end_date
      //   }).then(({ data: { result } }) => {
      //     this.loading = false;
      //     this.chartData = result;
      //     this.$emit('load');
      //   })
      // }
    },
  },
  mounted(){
    // setTimeout(() => {
      // console.log(this.barChart('speed', 'data').datasets[0].minimum);
      // console.log(this.barChart('speed', 'data'));
    // }, 2000);
  }
}
</script>
