<template lang="pug">
transition(name="modal")
  .modal-mask
    .modal-wrapper
      .modal-dialog(style="width:480px;max-width: 95%;" role="document")
        .modal-content.flow-29
          .modal-body.p-0
            .d-flex.justify-content-between.pt-3.px-4.mt-1
              h4.h5.m-0.text-dark.font-weight-bold Choose the vehicle and the limit
              a.bg-white.border-none(
                href="javascript:;"
                role="button",
                data-dismiss="modal",
                aria-label="Close"
                @click="$emit('close')"
              )
                div
                  img(:height="14" :width="14" src="@/assets/img/close.png")
            .py-4.my-2.px-4
              b-form-group(label="Device Vehicle Name")
                //- autocomplete(
                //-   :options="optionDevices"
                //-   v-on:selected="onSelectVehicle"
                //-   name="device_unit_id"
                //-   :maxItem="1000"
                //-   placeholder="Please select an option"
                //- )
                b-form-select(:options="optionDevices" v-model="device_unit_id")
              .row
                .col-5.pr-0
                  label.text-dark Limit
                .col-7.pl-4
                  label.text-dark Limit Value
              .row.mb-3(v-for="(item, index) in limitData")
                .col-5.pr-0
                  b-form-select(:options="optionLimits" v-model="item.limit")
                .col-7.pl-4
                  .row.align-items-center
                    .col-9
                      b-form-input(type="number" v-model="item.value")
                    .col-3.pl-0
                      a(href="javascript:;" v-if="index > 0" @click="removeLimit(index)")
                        .h3.text-danger.m-1
                          close-circle
              matador-button(:outline="true" icon="fa fa-plus" size="md" @click="onAddLimit")
                h5.m-0 Add another
              //- button.btn.btn-light.d-flex.align-items-center(@click="onAddLimit")
                .h4.m-0.mr-1
                  plus-circle
                | Add Another
            .py-3.px-4.d-flex.justify-content-end.align-items-center
              button.border-0.bg-white.py-1.px-4.text-danger(style="text-transform: none;" @click="$emit('close')") Cancel
              matador-button(style="text-transform: none;" v-if="alert==null" @click="onSave" :disabled="!allowToPost" size="md") Add
              matador-button(style="text-transform: none;" v-if="alert!=null" @click="onEdit" :disabled="!allowToPost" size="md") Save

</template>


<script>
import { getDeviceUnit, getSetting, postAlertSetting, postAddAlertSetting } from '../../utils/api';
import CloseCircle from "vue-material-design-icons/CloseCircleOutline.vue";
import PlusCircle from "vue-material-design-icons/PlusCircleOutline.vue";
import { get } from "lodash";
export default {
  components: {
    CloseCircle,
    PlusCircle
  },
  data(){
    return {
      optionDevices: [],
      optionLimits: [],
      device_unit_id: '',
      limitData: [
        {
          limit: null,
          value: null
        },
        {
          limit: null,
          value: null
        }
      ]
    }
  },
  watch: {
    alert: {
      immediate: true,
      handler: function(newVal){
        if(typeof newVal === 'object'){
          this.data_notification_id = get(newVal, 'data_notification_id');
          this.device_unit_id = get(newVal, 'device_unit_id');
          this.limitData = Object.keys(newVal.raw_limits).map((item, index) => {
            return {
              limit: item,
              value: Object.values(newVal.raw_limits)[index],
            }
          });
          // this.limitData = Object.keys(newVal.raw_limits).map(item => {
          //   // return {
          //   //   limit: Object.keys(item)[0],
          //   //   value: Object.values(item)[0],
          //   // }
          // });
        } else {
          this.limitData = [
            {
              limit: null, 
              value: null
            },
            {
              limit: null,
              value: null
            }
          ];
        }
        this.getDevices();
      },
      deep: true
    }
  },
  computed: {
    allowToPost(){
      const uncompleteLimit = this.limitData.filter(item => {
        return !item.limit || !item.value;
      });
      return !!this.device_unit_id && uncompleteLimit.length == 0;
    }
  },
  methods: {
    onAddLimit(){
      this.limitData.push({
        limit: null,
        value: null
      });
    },
    removeLimit(index){
      const limitData = JSON.parse(JSON.stringify(this.limitData));
      limitData.splice(index, 1);
      this.limitData = limitData;
    },
    onSave(){
      const limit = {}
      this.limitData.forEach(item => {
        limit[item.limit] = item.value;
      })
      
      postAddAlertSetting({
        device_unit_id: this.device_unit_id,
        limit
      }).then(({ data: { code,messgae } }) => {
        if(code==200){
        this.$toast.success('Successfully save settings');
        this.$emit('close');
        this.$emit('success');}
        else{
        this.$toast.error(messgae);
        this.$emit('close');
        }
      });
    },
    onEdit(){
      const limit = {}
      this.limitData.forEach(item => {
        limit[item.limit] = item.value;
      })
      console.log(this.device_unit_id,this.data_notification_id,limit)
      postAlertSetting({
        device_unit_id: this.device_unit_id,
        data_notification_id: this.data_notification_id,
        limit
      }).then(({ data: { code,messgae } }) => {
        if(code==200){
        this.$toast.success('Successfully save settings');
        this.$emit('close');
        this.$emit('success');}
        else{
        this.$toast.error(messgae);
        this.$emit('close');
        }
      });
    },    
    onSelectVehicle(value){
      this.device_unit_id = value.id;
    },
    getDevices(){
      getDeviceUnit({
        start: 0,
        limit: 10000,
        view_from: 'notification'
      }).then(({ data: { result } }) => {
        let optionDevices = result.map(item => {
          return {
            text: item.description,
            value: item.device_unit_id,
          }
        });
        if(this.alert){
          try {
            optionDevices.push({
              text: this.alert.device_name,
              value: this.alert.device_unit_id,
            })
          } catch (error) {
            
            }
        }
        this.optionDevices = optionDevices;
      });
    }
  },
  props: ['close', 'success', 'alert','add'],
  mounted(){
    this.getDevices();
    setTimeout(() => {
      document.getElementsByClassName('dropdown-input')[0].setAttribute('autocomplete', 'off');
    }, 1000);
    getSetting({
      setting_name: 'notification_limit_category'
    }).then(({ data: { result } }) => {
      this.optionLimits = result.map(item => {
        return {
          value: item,
          text: item.split('_').map(x => {
            return x[0].toUpperCase() + x.substring(1)
          }).join(' ').replace(/\sLimit/g, "")
        }
      });
    })
  }
}
</script>