import produce from "immer";
import { Line, mixins } from "vue-chartjs";
import { minifyChartAmount } from "../../utils/utilities";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["chart-data","options", "gradients"],
  data() {
    return {
      gradient: null,
      gradientOne:null,
      gradientTwo:null,
    };
  },
  methods: {
    update(){
      this.$data._chart.update()
    },
    create(){
      const chartData = this.chartData;
      
      if (this.gradients) {
        this.gradientOne=this.gradients[0];
        this.gradientTwo=this.gradients[1];
        this.gradient = this.$refs.canvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 450);
        this.gradient.addColorStop(0, this.gradients[0]);
        this.gradient.addColorStop(1, this.gradients[1]);
        chartData.datasets = chartData.datasets.map(item => {
          item.backgroundColor = this.gradient;
          return item;
        });
      }
      
      this.renderChart(
        chartData,
        produce(this.options || {}, draft => {
          draft.scales = {
            yAxes: [
              {
                ticks: {
                  beginAtZero: false,
                  mirror: false,
                  callback: function(value, index, ticks) {
                    if (value >= 1000000000) return Math.round(value / 1000000000) + ' B';
                    if (value >= 1000000) return Math.round(value / 1000000) + ' M';
                    if (value >= 1000) return Math.round(value / 1000) + ' K';
                    return value;
                  }
                }
              }
            ]
          };
        })
      );
    }
  },
  mounted() {
      if(this.chartData){

        const chartData = this.chartData;
        if (this.gradients) {
      this.gradientOne=this.gradients[0];
      this.gradientTwo=this.gradients[1];
      this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
      this.gradient.addColorStop(0, this.gradients[0]);
      this.gradient.addColorStop(1, this.gradients[1]);
      chartData.datasets = chartData.datasets.map(item => {
        item.backgroundColor = this.gradient;
        return item;
      });
    }
    
    this.renderChart(
      chartData,
      produce(this.options || {}, draft => {
        draft.scales = {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                mirror: false,
                callback: minifyChartAmount
              }
            }
          ]
        };
      })
      );
    }
  },
  };
  