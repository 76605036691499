<template lang="pug">
b-sidebar.bg(id="sidebar-detail" :visible="visible"  bg-variant="white" right no-header shadow style="" no-close)
  a.px-3.py-2.close(
    href="javascript:;"
    role="button",
    data-dismiss="modal",
    aria-label="Close"
    @click="$emit('close')"
    )
    div
    img(:height="20" :width="20" src="@/assets/img/close.png")      
  div.px-3.py-2
    h3 Order Details

  .d-flex.ml-3
    .matador-text-03 Status
  .d-flex.ml-3
    .matador-h5.ready( v-if="dataOrder.status=='Order Created'||dataOrder.status=='Order Confirmed'||dataOrder.status=='Order Ready'||dataOrder.status== 'On Rent'") {{dataOrder.status}}
    .matador-h5.endsoon( v-if="dataOrder.status=='End Soon'") {{dataOrder.status}}
    .matador-h5.timeout( v-if="dataOrder.status== 'Time Up' || dataOrder.status=='Order Canceled' ") {{dataOrder.status}}
    .matador-h5.done( v-if="dataOrder.status=='Returned'||dataOrder.status=='Order Done'") {{dataOrder.status}}
  .d-flex.mt-3.ml-3(style="width:100%")
      .col-4(style="padding:0px 0px !important")
        .matador-text-03 Order ID       
      .col-8
        span.matador-h5.matador-text-03 : {{detailOrder.order_id? detailOrder.order_id : ' - '}}
  .d-flex.ml-3(style="width:100%")
      .col-4(style="padding:0px 0px !important")
        .matador-text-03 Order Date      
      .col-8
        span.matador-h5.matador-text-03 : {{formatDateTime(detailOrder.order_date?detailOrder.order_date:nothing)}}
  .d-flex.ml-3(style="width:100%")
      .col-4(style="padding:0px 0px !important")
        .matador-text-03 Start Time    
      .col-8
        span.matador-h5.matador-text-03 : {{formatDateTime(detailOrder.start)}}
  .d-flex.ml-3(style="width:100%")
      .col-4(style="padding:0px 0px !important")
        .matador-text-03 Return Time    
      .col-8
        span.matador-h5.matador-text-03 : {{formatDateTime(detailOrder.finish)}}      
    

  //created
  .accordion.mt-3.ml-3.pr-3(role='tablist')
        b-button.matador-bg-03( @click="orderSummaryFunc()" style="width:100% !important;border-radius: 5px;") 
          .d-flex
            div.w-100(style="text-align: left !important;")
              .matador-h5.matador-text-03 Order Summary
            span.matador-text-03(v-show="showOrderSummary!=false" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
            span.matador-text-03(v-show="showOrderSummary!=true" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
        div(v-if="detailOrder.status == 'Order Created'") 
          b-collapse#accordion-1(:visible='showOrderSummary' accordion='my-accordion' role='tabpanel' appear)
            div(v-for="item in detailOrder.unit? detailOrder.unit : [] ")
              p {{item.index}}
              div.row.px-3 
                b-col(sm="4")
                  b-img(:src="item.image || 'car.png'" fluid thumbnail width="112" height="72")
                b-col(sm="5")            
                  h5 {{item.vehicle_type_id}}
                  p Qty        :{{item.qty}}
              .form-group
                p Assigned Vehicle
                b-form-checkbox.m-0.py-2(:checked="onAutoAssign") Auto Assign  
                multiselect(
                    v-model="selectedVin",
                    track-by="vin",
                    :multiple="true",
                    label="vin",
                    placeholder="Insert vehicle",
                    :options="optionVin",
                    :searchable="true",
                    :allow-empty="false",
                    :closeOnSelect="false"
                  )       
              p Amount   : {{mataUang(item.total_price)}}        
            br
            b-button(:disabled="disableConfirm" class="btn-confirm assign" @click="onConfirm()") Confirm
            hr
            div.row.px-3 
              b-col(sm="7")
                p Total
              b-col(sm="5")
                p Rp. {{total}} ,-


        //confirmed
        div(v-else)  
          b-collapse#accordion-1(:visible='showOrderSummary' accordion='my-accordion' role='tabpanel' appear)
            div.py-2(v-for="item in detailOrder.unit? detailOrder.unit : []")
              .d-flex  
                .col-3 
                  b-img(:src="item.image || 'car.png'" fluid thumbnail width="112" height="72")
                .col-3
                  h5 {{item.vehicle_type_id}}   
                  .matador-text-03  Qty        :{{item.qty}}
              .d-flex
                .col-2                   
                  span VIN
                  p Amount   
                .col-7
                  span(v-for="vin in item.vin") : {{vin? vin : '-'  }}
                  .matador-text-03(v-if="vin[0]==null") : -
                  .matador-text-03 : {{mataUang(item.total_price)}}  
                  
            hr
            div.row.px-3 
              b-col(sm="7")
                p Total
              b-col(sm="5")
                p Rp. {{total}} ,-

  .accordion.mt-4.ml-3.pr-3(role='tablist')
        b-button.matador-bg-03( @click="orderCustomerFunc()" style="width:100% !important;border-radius: 5px;") 
          .d-flex
            div.w-100(style="text-align: left !important;")
              .matador-h5.matador-text-03 Customer
            span.matador-text-03(v-show="showCustomer!=false" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
            span.matador-text-03(v-show="showCustomer!=true" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
        b-collapse#accordion-2(:visible='showCustomer' accordion='my-accordion#2' role='tabpanel' appear)
          .d-flex
            .col-3
              img.image-profile.mt-2.mb-lg-0(  src="@/assets/img/car-placeholder.png")
            .col-3.pt-2
              .matador-h5.matador-text-02 {{detailOrder.customer? detailOrder.customer : nothing }}
              .matador-text-02 {{detailOrder.email? detailOrder.email : nothing }}
              .matador-text-02 {{detailOrder.phone? detailOrder.phone : ' - '}}



  //PIC Info ready - onrent && endsoon  
  .accordion.mt-4.ml-3.pr-3(role='tablist' v-if="dataOrder.status != 'Order Created' && dataOrder.status != 'Order Confirmed'" )
    b-button.matador-bg-03( @click="orderPickUpFunc()" style="width:100% !important;border-radius: 5px;") 
          .d-flex
            div.w-100(style="text-align: left !important;")
              .matador-h5.matador-text-03 Pick Up PIC Information
            span.matador-text-03(v-show="showPickUp!=false" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
            span.matador-text-03(v-show="showPickUp!=true" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
    b-collapse#accordion-4(:visible='showPickUp' accordion='my-accordion#4' role='tabpanel' appear v-if="dataOrder.status=='Order Ready'")
          //order ready
          div.px-3.py-2()
            p Name
              b-form-input(
                id="input-name"
                placeholder="Enter Name"
                required)
            p.py-1 Phone Number
              b-form-input(
                id="input-phone"
                placeholder="Enter Phone Number"
                required) 
            b-button(class="btn-confirm pickup" @click="onPickUp") Confirm
            
    b-collapse#accordion-4(:visible='showPickUp' accordion='my-accordion#4' role='tabpanel' appear v-if="dataOrder.status=='On Rent'|| dataOrder.status=='End Soon'")
            .d-flex
              .col-3
                .matador-text-03 Name 
              .col-5
                .matador-h5.matador-text-02 : {{detailOrder.additional_data.pickup_by.name ? detailOrder.additional_data.pickup_by.name : ' - ' }}
            .d-flex
              .col-3
                .matador-text-03 Phone No.
              .col-5
                .matador-h5.matador-text-02 : {{detailOrder.additional_data.pickup_by.phone? detailOrder.additional_data.pickup_by.phone : '-' }}
        

  .accordion.mt-4.ml-3.pr-3(role='tablist' v-if="detailOrder.status == 'Time Up'|| detailOrder.status == 'Order Done'" )
        b-button.matador-bg-03( @click="orderReturnFunc()" style="width:100% !important;border-radius: 5px;") 
          .d-flex
            div.w-100(style="text-align: left !important;")
              .matador-h5.matador-text-03 Return PIC Information
            span.matador-text-03(v-show="showReturn!=false" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
            span.matador-text-03(v-show="showReturn!=true" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
        
        b-collapse#accordion-4(:visible='showReturn' accordion='my-accordion#4' role='tabpanel' appear  v-if="detailOrder.status == 'Time Up'")                        
          //time up            
          div.px-3.py-2()
            p Return PIC Information
            p Name
              b-form-input(
                id="input-name"
                placeholder="Insert Name"
                v-model="inputName"
                required)
            p.py-1 Phone Number
              b-form-input(
                id="input-phone"
                placeholder="Insert Phone Number"
                v-model="inputPhone"
                required) 
            p.py-1 Note
              b-form-input.py-4(
                id="input-phone"
                placeholder="Insert Note"
                v-model="inputNote"
                required)           
            b-button(class="btn-confirm return" @click="onReturn()" ) Return Confirmation 
        b-collapse#accordion-4(:visible='showReturn' accordion='my-accordion#4' role='tabpanel' appear  v-if="detailOrder.status == 'Order Done'")
          .d-flex
            .col-3
              .matador-text-03 Name 
            .col-5
              .matador-h5.matador-text-02 : {{detailOrder.additional_data.return_by.name}}
          .d-flex
            .col-3
              .matador-text-03 Phone No.
            .col-5
              .matador-h5.matador-text-02 : {{detailOrder.additional_data.return_by.phone}}
          .d-flex
            .col-3
              .matador-text-03 Note
            .col-5
              .matador-h5.matador-text-02 : {{detailOrder.additional_data.return_by.note}}

  .accordion.mt-3.ml-3.pr-3.mb-4(role='tablist')
        b-button.matador-bg-03( @click="orderTimelineFunc()" style="width:100% !important;border-radius: 5px;") 
          .d-flex
            div.w-100(style="text-align: left !important;")
              .matador-h5.matador-text-03 Timeline
            span.matador-text-03(v-show="showTimeline!=false" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
            span.matador-text-03(v-show="showTimeline!=true" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
        b-collapse#accordion-3(:visible='showTimeline' accordion='my-accordion#3' role='tabpanel' appear)
          div.px-3(v-for="item in detailOrder.timeline_status") 
            .d-flex(style="width:100%").mt-2
              .col-4
                a {{item.created_at.slice(0,10)}}
                br
                a {{item.created_at.slice(11,19)}}
              .col-2
                span.border-dot
                span.straight-line 
              .col-5
                span.text-order {{item.status}}  
</template>
<script>

import {
getDetailOrder,
postConfirmOrder,
postPickup,
getVehicleVINBySeries,
postReturn
} from "@/utils/api";
import dayjs from 'dayjs';
export default {
  data(){
    return{
      namePIC:null,
      phonePIC:null,
      order_unit_id:[],
      selectedVin : [],
      optionVin: [],
      vin : [],
      description:null,
      showReturn:true,
      showPickUp:true,
      showTimeline:true,
      showCustomer:true,
      showOrderSummary:true,
      date : null,
      detailOrder : null,
      total : 0,
      order_id:'',        
      inputName:'',
      inputPhone:'',
      inputNote:'',
    }
  },
watch:{      
    selectedVin: function (newVal) {
      this.vin = newVal.map(item => item.vin);
    },  
    visible: {
      handler: function(newVal){
        }
        },
    dataOrder: {
      handler: function(newVal){
        if(newVal){
        newVal.order_date =  newVal.order_date.replace('T',' ').split('.',1).toString()
        this.fetchDetailOrder(newVal.order_id)
        }
        }
        },
                  
},

  props: ['close', 'visible','dataOrder', 'success'], 
  computed:{
    disableConfirm(){
      return this.vin.length != this.detailOrder.unit[0]['qty']
    },
    disablePIC(){
      return this.namePIC||this.phonePIC
    },
    disableReturn(){
      return this.inputName||this.inputPhone||this.inputNote
    }
  }, 
  methods:{
    onAutoAssign(){
      // this.selectedVin = this.detailOrder.unit.map(item => item.vin)
    },
    onReturn(){
      let returning = {
        order_id : this.order_id,
        name: this.inputName,
        phone: this.inputPhone,
        note:this.inputNote,
      }
      postReturn(returning).then(({ data: { result, code, message } }) => {
        if(code === 200) {
          this.$toast.success("Succesfully Added PIC");
          this.$router.push({path: '/orderList?order_id='+this.order_id});
        } else {
          this.$toast.error(message);
        }
      });
    },
    onPickUp(){
      let pickup = {
        order_id : this.order_id,
        name: this.namePIC,
        phone: this.phonePIC,
      }
      postPickup(pickup).then(({ data: { result, code, message } }) => {
        if(code === 200) {
          this.$toast.success(message);
          this.vin.length == this.detailOrder.unit[0]['qty']
          this.$router.push({path: '/orderList?order_id='+this.order_id});          
        } else {
          this.$toast.error(message);
        }
      });

    },
    
    onConfirm(){
        // console.log("v-model selectedvin",this.selectedVin)
        // console.log("label description ",this.description)
        // console.log("options optionVin ",this.optionVin)
        // console.log("track-by vin",this.vin)   
        // console.log("series",this.detailOrder.series)   
        // console.log("type",this.detailOrder.vehicle_type_id)   
        // console.log("vin",this.optionVin)
      
      let confirm = {
        order_id:this.order_id,
        unit:[{
          order_unit_id: this.order_unit_id,
          vin: this.vin
        }],
        confirm_by:this.$store.state.auth.user_id
      }
      // console.log("vin",this.vin.length)
      // console.log("qty",this.detailOrder.unit[0]['qty'])

      if(this.vin.length == this.detailOrder.unit[0]['qty']){
        postConfirmOrder(confirm).then(({ data: { result, code, message } }) => {
          if(code === 200) {
            this.$toast.success("Order Successfuly Confirmed");
            this.$router.push({path: '/orderList?order_id='+this.order_id});
          } else {
            this.$toast.error(message);
          }
      });      }else{
        this.$toast.error("Vehicle quantity and VIN count mismatch ");
      }

    },
    orderReturnFunc(){
      if(this.showReturn){
        this.showReturn=false
      }else{
        this.showReturn=true
      }
    },
    orderPickUpFunc(){
      if(this.showPickUp){
        this.showPickUp=false
      }else{
        this.showPickUp=true
      }
    },
    orderTimelineFunc(){
      if(this.showTimeline){
        this.showTimeline=false
      }else{
        this.showTimeline=true
      }
    },
    orderCustomerFunc(){
      if(this.showCustomer){
        this.showCustomer=false
      }else{
        this.showCustomer=true
      }
    },
    orderSummaryFunc(){
      if(this.showOrderSummary){
        this.showOrderSummary=false
      }else{
        this.showOrderSummary=true
      }
    },
    formatDate(params){
      var date=dayjs(params).format('DD/MM/YYYY')
      return date
    },
    formatDateTime(params){
      var date=dayjs(params).format('DD/MM/YYYY HH:mm')
      return date
    },
    splitDate(value){
      return value.replace('T',' ')
    },
    fetchDetailOrder(params){
        this.optionVin = []
        this.selectedVin = []
        getDetailOrder({order_id:params}).then(({ data: { code, result, message } }) => {
          if(code==200){
               getVehicleVINBySeries({series:result.unit[0]['series'],vehicle_type_id:result.unit[0]['vehicle_type_id']}).then(({ data: { code,result } })=>{
                  if (code == 200) {  
                    this.optionVin = result.vehicle.map(item => {
                            item.value = item.unit_id;
                            item.text = `${item.vin}`;
                          // console.log(item)
                          return item;
                          }) 
                        }else{
                          this.optionVin=[];
                        }
               });
            this.order_unit_id = result.unit[0]['order_unit_id']
            this.order_id = result.order_id
            this.total=0
            result.unit.forEach((value, index) => {
            this.total+= value.total_price
          });
          this.detailOrder = result
          }
      });
    },
    mataUang(req){
      if(req){
      var	number_string = req.toString()
      var sisa 	= number_string.length % 3
      var rupiah 	= number_string.substr(0, sisa)
      var ribuan 	= number_string.substr(sisa).match(/\d{3}/g)
          
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
    
      return 'Rp. '+rupiah;
      }else{
        return req
      }
    } ,
    separateDate(date){
      
    
  }   
  },

  mounted(){
  }
}
</script>
<style lang="scss" scope>
@import "../../../assets/sass/paper/variables.scss";
.text-title-3
{
  color: #505565;
  /* Body 3 Semi Bold */
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
}
#sidebar-detail.b-sidebar{
  width: 35% !important;

}
    .ready {
      color:#0CCA4A !important}
    .endsoon{
      color:#FC9905
    }
    .timeout{
      color:#992024
    }
    .done{
      color:#005BFF
    }
  
.image-profile{
  width: 72px;
  height: 72px;
  left: 0px;
  top: 0px;

}
.btn-confirm
{
  border: #0CCA4A !important;
  background : #0CCA4A !important;
  border-radius: 5px !important;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  &.assign{
      width: 136px;
      height: 36px;
  }
  &.pickup{
      width: 136px;
      height: 36px;
  }
  &.return{
      width: 223px;
      height: 36px; 
  }
}
.date-line{

}
.time-line{

}
.green-dot {
  width: 20px;
  height: 20px;
  // left: 99px;
  // top: 10px;
  
  background-color: #0CCA4A;
  border-radius: 50%;
  display: inline-block;
}
.border-dot {
  width: 20px;
  height: 20px;
  border: 2px solid #0CCA4A;
  box-sizing: border-box;
  border-radius: 50%;
  display: inline-block;
}
.staight-line {
position: absolute;
  width: 1px;
  height: 51px;

  border: 2px solid #0CCA4A;
}
.text-order{
color: #232735;
width: 97px;
height: 24px;
left: 129px;
top: 0px;

/* Body 3 Semi Bold */
font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 24px;
}
</style>