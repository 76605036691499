import { Perms } from '@/utils/rbac'

export const createSidebar = (self, params) => {
  let { key, to, name, icon, counter } = params
  let role = self.$store.state.auth.role

  return {
    allow: self.$store.getters.canAccess(role, key, Perms.READ),
    counter,

    // default attributes
    key,
    to,
    name,
    icon
  }
}


export const initSidebar = (self) => {
  return [
    createSidebar(self, { key: 'catalogue_user', to: '/catalogue', name: 'Home Page', icon: 'mdi mdi-home-outline' }),
    createSidebar(self, { key: 'superboard', to: '/superboard', name: 'Superboard', icon: 'mdi mdi-shield-outline' }),
    // createSidebar(self, {key: 'dashboard', to: '/dashboard_v3_1', name: 'Dashboard v3.1', icon: 'mdi mdi-home-outline'}),
    createSidebar(self, { key: 'dashboard', to: '/dashboard', name: 'Dashboard', icon: 'mdi mdi-home-outline' }),
    createSidebar(self, { key: 'monitoring', to: '/monitoring', name: 'Monitoring', icon: 'mdi mdi-monitor' }),
    createSidebar(self, { key: 'alert', to: '/alert', name: 'Alert', counter: "alertCounter", icon: 'mdi mdi-alert-circle-outline' }),
    createSidebar(self, { key: 'tracks', to: '/tracks', name: 'Tracks', icon: 'mdi mdi-call-split' }),
    createSidebar(self, { key: 'reports', to: '/reports', name: 'Reports', icon: 'mdi mdi-view-list-outline' }),
    createSidebar(self, { key: 'geofence', to: '/geofence', name: 'Geofence', icon: 'mdi mdi-web' }),
    createSidebar(self, { key: 'drivers', to: '/drivers', name: 'Drivers', icon: 'mdi mdi-lifebuoy' }),
    createSidebar(self, { key: 'rental', to: '/catalogue', name: 'Rental', icon: 'mdi mdi-train-car' }),
    createSidebar(self, { key: 'dashboard_rental', to: '/dashboardRental', name: 'Rental', icon: 'mdi mdi-train-car' }),
    createSidebar(self, { key: 'vehicles', to: '/vehicles', name: 'Vehicles', icon: 'mdi mdi-truck' }),
    createSidebar(self, { key: 'satellite', to: '/vehicle_satellites', name: 'Satellite', icon: 'fa fa-wifi' }),
    createSidebar(self, { key: 'devices', to: '/devices', name: 'Devices', icon: 'mdi mdi-cellphone-wireless' }),
    createSidebar(self, { key: 'users', to: '/users', name: 'Users', icon: 'mdi mdi-account-box-outline' }),
    createSidebar(self, { key: 'privilege', to: '/privilage', name: 'User Privilege', icon: 'mdi mdi-account-clock-outline' }),
    createSidebar(self, { key: 'generator', to: '/generators', name: 'Generator', icon: 'fa fa-bolt' }),
    createSidebar(self, { key: 'teltonika', to: '/trackerv2', name: 'Tracker V2', icon: 'fa fa-microchip' }),
    createSidebar(self, { key: 'setting', to: '/setting', name: 'Settings', icon: 'mdi mdi-cogs' }),
  ]
}