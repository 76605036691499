<template lang="pug">
#sosNotifictionFloating.d-flex.justify-content-center.align-items-center(v-if="totalSOS > 0")
  router-link(:to="'/drivers/emergencies'")
    | {{ totalSOS }}
</template>

<script>
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState(['totalSOS'])
  },
}
</script>

<style lang="scss">

@keyframes coloring {
  0% { background: #ff0000; }
  50% { background: #ffbaba; }
  100% { background: #ff0000; }
}

#sosNotifictionFloating {
  position: fixed;
  bottom: 50px;
  right: 20px;
  width: 40px;
  height: 40px;
  
  z-index: 10000;
  color: #ffffff;
  cursor: pointer;
  border-radius: 50%;
  animation: coloring 1s infinite; 

  a {
    color: #ffffff;
    font-weight: bold;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>